@font-face {
  font-family: PencilCase;
  src: url("./assets/fonts/pencilcase/pencilCASE.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  margin: 20px;
  font-family: "Montserrat", cursive, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  /* display: none; */
}

@media only screen and (max-width: 600px) {
}

/*
  ANIMATIONS
*/

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translateY(0px);
    transform: translateX(0px);
  }
}

@keyframes blink {
  0% {
    box-shadow: 0px 0px 0px #fff;
  }
  50% {
    box-shadow: 0px 0px 40px #fff;
  }
  100% {
    box-shadow: 0px 0px 0px #fff;
  }
}
