.PurchaseCard {
  background: rgba(0, 0, 0, 0);
  border-radius: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 20px;

  z-index: 100;
}

.PurchaseCard p {
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.PurchaseCard img {
  position: absolute;
  top: -10px;
  width: 250px;
}

.simple-text {
  font-family: 'Montserrat';
  text-decoration: underline;
}

.PurchaseCard button {
  background: linear-gradient(to right, #eace00, #8bd4ef);
  border-radius: 20px;
  color: white;
  padding: 10px;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "SWEETREVENGE", sans-serif;
  border: none;
  cursor: pointer;
  transition: all 1s;
  margin-bottom: 20px;
  margin-top: 20px;
}

.PurchaseCard_ActionButton {
  background: linear-gradient(to right, #eace00, #8bd4ef);
  border-radius: 20px;
  color: white;
  padding: 20px !important;
  font-size: 1.4rem !important;
  font-weight: bold;
  font-family: "SWEETREVENGE", sans-serif;
  border: none;
  cursor: pointer;
  transition: all 1s;
  margin-bottom: 20px;
  margin-top: 20px;
}

.react-numeric-input input {
  padding-right: 3ex !important;
  padding-left: 3ex !important;
  min-height: 35px;
  font-family: "Montserrat", cursive;
  color: black;
  background: transparent;
  outline: none;
  border: 1px solid black;
}

.react-numeric-input b {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: transparent !important;
}

.react-numeric-input b:hover {
  cursor: pointer !important;
}

.react-numeric-input b:nth-child(3) {
  border-right: 1px solid black !important;
}

.react-numeric-input b:nth-child(2) {
  border-left: 1px solid black !important;
}

.react-numeric-input b i {
  font-family: "Press Start 2P", cursive !important;
  background: black !important;
}

.Modal_Body {
  font-family: CaliforniaSunshineBasic;
}

.skylight-dialog {
  background: linear-gradient(to right, #eace00, #8bd4ef) !important;
  color: black !important;
  border-radius: 20px !important;
}

.separator {
  height: 2px;
  background-color: black;
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat";
}

.Modal_Title {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
}

.Modal_Title p {
  margin-left: 20px;
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: bold;
}

.skylight-close-button {
  top: 10px !important;
  right: 20px !important;
}

.Modal_Entries {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Modal_Entry {
  font-size: 0.9rem;
  display: flex;
  font-weight: bold;
  font-family: "Montserrat";
  justify-content: space-between;
}

.Modal_Entry a {
  margin-left: 10px;
  color: blue;

}

.Modal_Actions {
  display: flex;
  justify-content: center;
}

.Modal_Actions button {
  background: white;
  border: none;
  color: black;
  font-family: "Montserrat";
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  transition: 1s;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.Modal_Confirmation {
  background: green;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  animation: flash 2.5s ease-in infinite;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 0.8rem;
  
}

.Modal_Confirmation a {
  text-decoration: underline;
}

.Modal_Status {
  text-align: left !important;
}

.Modal_Status span {
  color: black;
  font-size: 0.75rem !important;
}

.rdl-list-box {
  min-width: 45vw !important;
  min-height: 45vh !important;
}

@media only screen and (max-width: 600px) {
  .PurchaseCard {
    min-width: unset;
    margin-right: 0;
  }

  .skylight-dialog {
    /* width: 100% !important; */
    /* margin-left: unset !important; */
    /* transform: translate(-50%, 0) !important; */
  }

  .Modal_Status {
    font-size: 0.6rem !important;
  }

  .skylight-close-button {
    font-size: 1.4rem !important;
  }
  .Modal_Status span {
    color: yellow;
    font-size: 0.6rem !important;
  }
  .Modal_Entries {
    flex-direction: column;
    justify-content: center;
  }
  .Modal_Entry {
    flex-direction: column;
    font-size: 0.6rem;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .Modal_Entry p {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .Modal_Actions {
    flex-direction: column;
  }

  .react-dual-listbox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rdl-actions {
    flex-direction: row;
  }

  .rdl-list-box {
    min-width: 80vw !important;
    min-height: 500px !important;
  }

  .rdl-align-top .rdl-selected {
    margin-left: 0;
  }

  .ph-image-inner {
    display: none;
  }
}

.rdl-filter,
.rdl-control {
  font-size: 1.8rem;
}

.rdl-control optgroup {
  font-size: 1.4rem;
  font-family: "Montserrat";
  color: white;
}

select[multiple] option {
  font-size: 1rem !important;
  text-align: center;
  padding: 0;
  margin: 0;
  color: white !important;
  font-family: "Montserrat";
}

optgroup option::before {
  content: "" !important;
}

.react-dual-listbox button,
.react-dual-listbox select {
  background-color: black;
}
