.sidebar {
  position: absolute;
  width: 0vw;
  visibility: hidden;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  right: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s all;
  z-index: 9999 !important;
}

.sidebarclose {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.open {
  width: 100vw;
  visibility: visible;
}

.sidebar.closed .sidebarclose {
  display: none;
}
.sidebar.closed input {
  display: none;
}
.sidebar.closed .ph-caption {
  display: none;
}
.sidebar:not(.closed) input {
  width: 300px;
  height: 35px;
  border: none;
  border-bottom: yellow 1px solid;
  background-color: transparent;
  z-index: 9999 !important;
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
}

.sidebar:not(.closed) button {
  background: linear-gradient(to right, #eace00, #8bd4ef);
  border-radius: 20px;
  color: white;
  padding: 20px !important;
  font-size: 1.4rem !important;
  font-weight: bold;
  font-family: "SWEETREVENGE", sans-serif;
  border: none;
  cursor: pointer;
  transition: all 1s;
  margin-bottom: 20px;
  margin-top: 20px;
}
