* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  background-color: #000000;
  line-height: 1.7;
  font-size: 19px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  overflow-x: hidden;
}

p {
  margin: 0 0 30px;
}

a {
  color: currentColor;
  text-decoration: none;
  outline: none;
}
a:hover,
a:focus {
  color: currentColor;
}
p a {
  color: currentColor;
  opacity: 0.7;
  font-style: italic;
  border-bottom: 1px solid;
}
p a:hover,
p a:focus {
  color: currentColor;
  opacity: 0.6;
}

img {
  max-width: 100%;
  height: auto;
}

b,
strong {
  font-weight: bold;
  font-weight: 700;
}

section {
  position: relative;
}

button {
}
button:focus {
  outline: none;
}

.small,
small {
  font-size: 80%;
}

hr {
  width: 100%;
  height: 0;
  border: none;
  border-bottom: 1px solid rgb(144 144 144 / 30%);
}

.anim-image-parallax {
  transform: scale(1.2);
  transform-origin: 50% 100%;
}

/* Selection */
::selection {
  color: #fff;
  text-shadow: none;
  background: #4c2122;
}
::-moz-selection {
  color: #fff;
  text-shadow: none;
  background: #4c2122; /* Firefox */
}
::-webkit-selection {
  color: #fff;
  text-shadow: none;
  background: #4c2122; /* Safari */
}

/* Lists */
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

/* Disable scroll */
.tt-no-scroll {
  overflow-y: hidden !important;
}

/* ------------------------------------------------------------- *
   * Fonts
  /* ------------------------------------------------------------- */

/* Body font
  ============= */
body {
  font-family: "SWEETREVENGE", sans-serif;
}

/* Secondary font
  ================== */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
#ball,
.tt-main-menu,
.ph-caption-title-ghost,
.ph-caption-subtitle,
.scroll-down-circle,
.made-with-love,
.tt-ol-menu-toggle-btn-text,
.tt-m-menu-toggle-btn-text,
.tt-ol-menu-list,
.tt-heading,
.tt-btn,
.social-buttons,
.footer-social,
.tt-ps-nav-pagination,
.tt-pc-navigation,
.tt-cc-pagination,
.pli-counter,
.ttgr-cat-trigger-wrap,
.ttgr-cat-list,
.portfolio-interactive-item,
.tt-copyright,
.project-info-list,
.tt-pn-link,
.tt-pn-subtitle,
.ph-share-ghost,
.tt-pagination,
.tt-scrolling-text,
#blog-list.bli-compact .bli-info::before,
.blog-interactive-item::before {
  font-family: "SWEETREVENGE", sans-serif;
}

/* ------------------------------------------------------------- *
   * Page transitions
  /* ------------------------------------------------------------- */

#page-transition {
  display: none;
}
body.tt-transition #page-transition {
  position: relative;
  display: block;
  z-index: 99999;
}

/* Transition overlay */
body.tt-transition .ptr-overlay {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0a0a0a;
  z-index: 1;
}

/* Transition preloader */
body.tt-transition .ptr-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

body.tt-transition .ptr-prel-content {
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  color: #af1212;
}

.ptr-prel-image {
  max-height: 96px; /* You may need to change the img height to match your logo type! */
  opacity: 0.2; /* You may need to change the opacity as well! */
}

/* ----------------------------------------------------------------------------------------- *
   * Page boxed layout
   * Note: Class "boxed" in <body> tag is connected with class "tt-wrap" in several places!
  /* ----------------------------------------------------------------------------------------- */

body.tt-boxed .tt-wrap {
  max-width: 1282px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

body.tt-boxed .tt-wrap .tt-wrap {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* ------------------------------------------------------------- *
   * Body inner
  /* ------------------------------------------------------------- */

#body-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
}

/* ------------------------------------------------------------- *
   * Content wrap
  /* ------------------------------------------------------------- */

#content-wrap {
  position: relative;
  overflow: hidden;
}

/* ------------------------------------------------------------- *
   * Page content
  /* ------------------------------------------------------------- */

#page-content {
}

/* ------------------------------------------------------------- *
   * Smooth Scrollbar
   * Source: https://github.com/idiotWu/smooth-scrollbar/
  /* ------------------------------------------------------------- */

/* Hide default scrollbar */
body:not(.is-mobile).tt-smooth-scroll {
  overflow: hidden;
}

/* Scroll content (added if <body> contain class "tt-smooth-scroll". Disabled on mobile devices!) */
body:not(.is-mobile).tt-smooth-scroll #scroll-container {
  width: auto;
  height: 100vh;
  overflow: auto;
  margin: 0;
}

/* Styling scrollbar */
.scrollbar-track {
  background: transparent !important;
  z-index: 99999 !important;
}
.scrollbar-track:hover {
  /*background: rgba(222, 222, 222, 0.1) !important;*/
}
.scrollbar-thumb {
  background: #ddd !important;
  opacity: 0.25;
  transition: opacity 0.2s ease-in-out;
}
.scrollbar-track:hover .scrollbar-thumb {
  opacity: 0.5;
}

/* ------------------------------------------------------------- *
   * Magic cursor
  /* ------------------------------------------------------------- */

#magic-cursor {
  position: absolute;
  display: none;
  width: 10px;
  height: 10px;
  pointer-events: none;
  z-index: 99999;
  opacity: 0;
}
body.tt-magic-cursor #magic-cursor {
  display: block;
}

#ball {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  pointer-events: none;
  border-style: solid;
  border-color: #fff;
  border-radius: 50%;
  /* Note: border width handled through JS */
}

/* Ball view 
  ============= */
#ball .ball-view {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  line-height: 1.3;
  text-align: center;
  transform: scale(0);
}

#ball .ball-view i {
  font-size: 19px;
}

/* Ball drag 
  ============= */
#ball .ball-drag {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  transition: all 0.3s;
}
#ball .ball-drag::before,
#ball .ball-drag::after {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  font-size: 19px;
  color: #fff;
  height: 10px;
  line-height: 10px;

  /* Font Awesome */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
#ball .ball-drag::before {
  content: "\f104"; /* Font Awesome */
  left: 0;
  transform: translate3d(-30px, 0, 0);
  transition: all 0.25s;
}
#ball .ball-drag::after {
  content: "\f105"; /* Font Awesome */
  right: 0;
  transform: translate3d(30px, 0, 0);
  transition: all 0.25s;
}

/* Ball close 
  ============== */
#ball.ball-close-enabled {
  opacity: 1 !important;
}
#ball .ball-close {
  position: absolute;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  text-align: center;
}

/* ------------------------------------------------------------- *
   * tt-Section
  /* ------------------------------------------------------------- */

.tt-section {
  position: relative;
  padding: 80px 0;
}
@media (max-width: 991px) {
  .tt-section {
    padding: 6% 0;
  }
}
@media (max-width: 767px) {
  .tt-section {
    padding: 10% 0;
  }
}
@media (max-width: 480px) {
  .tt-section {
    padding: 15% 0;
  }
}

/* tt-section background image 
  =============================== */
.tt-section-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
}

/* tt-section inner 
  ==================== */
.tt-section-inner {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

/* ------------------------------------------------------------- *
   * Headings
  /* ------------------------------------------------------------- */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-weight: 500;
  color: inherit;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  line-height: 1.4;
  margin-top: 0px;
  margin-bottom: 30px;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 30px;
}

.h1,
h1 {
  font-size: 46px;
}
.h2,
h2 {
  font-size: 42px;
}
.h3,
h3 {
  font-size: 38px;
}
.h4,
h4 {
  font-size: 34px;
}
.h5,
h5 {
  font-size: 30px;
}
.h6,
h6 {
  font-size: 26px;
}

/* ------------------------------------------------------------- *
   * tt-Headings (custom headings)
  /* ------------------------------------------------------------- */

.tt-heading {
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 991px) {
  .tt-heading {
    margin-bottom: 30px;
  }
}

/* tt-Headings title */
.tt-heading-title {
  margin: 0;
  font-size: calc(24px + 1vw);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
}

/* tt-Headings subtitle */
.tt-heading-subtitle {
  margin: 0 0 15px 0;
  font-size: calc(19px + 0.1vw);
  font-weight: bold;
  font-weight: normal;
  color: #fff;
}
.tt-heading-title + .tt-heading-subtitle {
  margin: 15px 0 0 0;
}

/* tt-Headings paragraph */
.tt-heading > p:first-child {
  margin-bottom: 0;
}
.tt-heading-title + p,
.tt-heading-subtitle + p {
  margin-top: 50px;
}
.tt-heading > p:last-child {
  margin-bottom: 0;
}

/* tt-Headings styles 
  ====================== */
/* style stroke */
.tt-heading.tt-heading-stroke .tt-heading-title {
  font-weight: bold;
  font-weight: 800;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: transparent;
}

/* If "text-gray" is used */
.tt-heading.tt-heading-stroke .tt-heading-title.text-gray {
  -webkit-text-stroke-color: #a2a2a2;
}

/* If Firefox */
body.is-firefox .tt-heading.tt-heading-stroke .tt-heading-title {
  -webkit-text-stroke-width: 2px;
}

/* tt-Headings positions 
  ========================= */
/* position center */
.tt-heading.tt-heading-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/* tt-Headings sizes 
  ===================== */
/* size xsm */
.tt-heading.tt-heading-xsm .tt-heading-title {
  font-size: calc(24px + 0.1vw);
  font-weight: bold;
  font-weight: 600;
}
.tt-heading.tt-heading-xsm .tt-heading-subtitle {
  font-size: calc(13px + 0.1vw);
}

/* size sm */
.tt-heading.tt-heading-sm .tt-heading-title {
  font-size: calc(24px + 0.5vw);
  font-weight: bold;
  font-weight: 600;
}
.tt-heading.tt-heading-sm .tt-heading-subtitle {
  font-size: calc(15px + 0.1vw);
}

/* size lg */
.tt-heading.tt-heading-lg .tt-heading-title {
  font-size: calc(28px + 2vw);
  line-height: 1.3;
}
.tt-heading.tt-heading-lg .tt-heading-subtitle {
  font-size: calc(21px + 0.1vw);
  font-weight: bold;
  font-weight: 500;
}

/* size xlg */
.tt-heading.tt-heading-xlg .tt-heading-title {
  font-size: calc(28px + 3vw);
  line-height: 1.2;
}
@media (min-width: 1025px) {
  .tt-heading.tt-heading-xlg.tt-heading-stroke .tt-heading-title {
    -webkit-text-stroke-width: 1px;
  }
}
.tt-heading.tt-heading-xlg .tt-heading-subtitle {
  font-size: calc(21px + 0.2vw);
  font-weight: bold;
  font-weight: 500;
}

/* size xxlg */
.tt-heading.tt-heading-xxlg .tt-heading-title {
  font-size: calc(28px + 4.5vw);
  line-height: 1.2;
}
@media (min-width: 1025px) {
  .tt-heading.tt-heading-xxlg.tt-heading-stroke .tt-heading-title {
    -webkit-text-stroke-width: 1px;
  }
}
.tt-heading.tt-heading-xxlg .tt-heading-subtitle {
  font-size: calc(21px + 0.3vw);
  font-weight: bold;
  font-weight: 500;
}

/* ------------------------------------------------------------- *
   * Header
  /* ------------------------------------------------------------- */

#tt-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 999;
  pointer-events: none;
}

/* header position fixed */
#tt-header.tt-header-fixed {
  position: fixed;
}

/* header inner */
.tt-header-inner {
  width: 100%;
  display: flex;
  padding-top: 40px;
  align-items: center;
}
@media (max-width: 1024px) {
  .tt-header-inner {
    padding-top: 20px;
  }
}

body:not(.tt-boxed) .tt-header-inner,
.tt-header-inner:not(.tt-wrap) {
  padding-left: 3.5%;
  padding-right: 3.5%;
}

/* header columns */
.tt-header-col {
  display: flex;
  align-items: center;
}
.tt-header-col:first-child {
  margin-right: auto;
}

/* Header logo 
  =============== */
.tt-logo {
  position: absolute;
  top: 40px;
  margin-right: auto;
  line-height: 1;
  pointer-events: initial;
  z-index: 9;
}
.tt-logo a {
  display: inline-block;
  font-size: 21px;
  color: #fff;
}
.tt-logo img {
  max-height: 48px; /* You may need to change the img height to match your logo type! */
}

.tt-logo-dark {
  display: none;
}

/* Logo for small screens */
@media (max-width: 1024px) {
  .tt-logo {
    top: 20px;
  }
  .tt-logo img {
    max-height: 42px; /* You may need to change the img height to match your logo type! */
  }
}

/* ------------------------------------------------------------- *
   * Classic menu
  /* ------------------------------------------------------------- */

/* Classic menu (desktop)
  ========================== */
@media (min-width: 1025px) {
  .tt-main-menu {
    pointer-events: initial;
  }
  .tt-main-menu-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0; /* inline list cap fix */
  }
  .tt-main-menu-list > li {
    display: inline-block;
    font-size: inherit; /* inline list cap fix */
    padding: 0 20px;
    transition: opacity 0.2s;
  }
  .tt-main-menu-list > li:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .tt-main-menu-list > li:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .tt-main-menu-list > li > a,
  .tt-main-menu-list > li > .tt-submenu-trigger > a {
    display: block;
    padding: 20px 5px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    transition: color 0.3s, opacity 0.3s;
  }

  /* Main menu hover */
  .tt-main-menu-list.tt-mm-hover > li > a,
  .tt-main-menu-list.tt-mm-hover > li > .tt-submenu-trigger > a {
    opacity: 0.6;
  }
  .tt-main-menu-list.tt-mm-hover > li > a:hover,
  .tt-main-menu-list.tt-mm-hover > li > .tt-submenu-trigger > a:hover,
  .tt-main-menu-list.tt-mm-hover > li.active > a,
  .tt-main-menu-list.tt-mm-hover > li.active > .tt-submenu-trigger > a,
  .tt-main-menu-list.tt-mm-hover
    > li.tt-submenu-open
    > .tt-submenu-trigger
    > a {
    opacity: 1;
    color: #fff;
  }

  /* Submenu 
      ============ */
  .tt-submenu-wrap {
    position: relative;
  }

  .tt-submenu {
    position: absolute;
    display: block !important;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #fff;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    z-index: 99999;
    border-radius: 3px;
    transform: translate3d(0, 10px, 0);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  /* Open submenu on hover */
  .tt-submenu-wrap.tt-submenu-open > .tt-submenu {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.1s;
  }

  /* submenu links */
  .tt-submenu-list {
    margin: 0;
    padding: 15px 0;
    list-style: none;
  }
  .tt-submenu-list li {
    width: 100%;
  }
  .tt-submenu-list li:first-child {
  }
  .tt-submenu-list li:last-child {
  }
  .tt-submenu-list li a {
    position: relative;
    display: block;
    padding: 10px 25px 10px 20px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #111;
    transition: all 0.2s ease-in-out;
  }
  .tt-submenu-list > li a:hover,
  .tt-submenu-list > li > .tt-submenu-trigger:hover a,
  .tt-submenu-list > li.active > a,
  .tt-submenu-list > li.active > .tt-submenu-trigger a {
    background-color: rgba(0, 0, 0, 0.05);
  }

  /* Set submenu position to right on last menu list element */
  .tt-main-menu-list > li:last-child > .tt-submenu {
    left: auto;
    right: 0;
  }

  /* Submenu toggle */
  .tt-m-caret {
    display: none;
  }

  /* Sub-submenu */
  .tt-submenu .tt-submenu-wrap > .tt-submenu {
    left: 100%;
    right: auto;
    margin-top: -50px;
    margin-left: 0;
  }

  /* Caret (requires FontAwesome: https://fontawesome.com/) */
  .tt-submenu-trigger > a::after {
    margin-left: 10px;
    font-size: 12px;
    line-height: 0;

    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  /* Caret in sub-submenus */
  .tt-submenu-wrap .tt-submenu-wrap .tt-submenu-trigger > a::after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(-90deg);
  }

  /* Hide ".tt-submenu-trigger-m" on desktop */
  .tt-submenu-trigger .tt-submenu-trigger-m {
    display: none;
  }

  /* Submenu styles 
      ================== */
  /* Submenu dark style */
  .tt-submenu-dark .tt-submenu {
    background-color: #212121;
    color: #a9a9a9;
  }
  .tt-submenu-dark .tt-submenu .tt-submenu-list li a {
    color: #a9a9a9;
  }
  .tt-submenu-dark .tt-submenu .tt-submenu-list > li a:hover,
  .tt-submenu-dark
    .tt-submenu
    .tt-submenu-list
    > li
    > .tt-submenu-trigger:hover
    a,
  .tt-submenu-dark .tt-submenu .tt-submenu-list > li.active > a,
  .tt-submenu-dark
    .tt-submenu
    .tt-submenu-list
    > li.active
    > .tt-submenu-trigger
    a {
    color: #fff;
    background-color: transparent;
  }
}

/* Mobile menu (for classic menu) 
  ================================== */
@media (min-width: 1025px) {
  #tt-m-menu-toggle-btn-wrap {
    /* Hide on desktop */
    display: none;
  }
}

/* Show mobile menu on small screens only */
@media (max-width: 1024px) {
  .tt-main-menu {
    pointer-events: initial;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0a0a0a;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
  }
  .tt-main-menu-holder {
    position: relative;
    height: 100%;
    width: calc(100% + 17px);
    overflow-y: scroll;
    z-index: 2;
  }
  body.is-mobile .tt-main-menu-holder {
    padding-right: 17px;
  }
  .tt-main-menu-inner {
    display: table;
    width: 100%;
    height: 100%;
    padding: 20% 7%;
  }
  .tt-main-menu-content {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  /* Mobile menu list */
  .tt-main-menu-list {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .tt-main-menu-list > li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .tt-main-menu-list > li:first-child {
    margin-top: 0;
  }
  .tt-main-menu-list > li:last-child {
    margin-bottom: 0;
  }
  .tt-main-menu-list > li a {
    position: relative;
    display: inline-block;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.2;
    color: #eaeaea;
  }
  @media (max-width: 767px) {
    .tt-main-menu-inner {
      padding-top: 35%;
      padding-bottom: 35%;
    }
    .tt-main-menu-list > li a {
      font-size: 34px;
    }
  }

  /* Mobile menu list hover/active (master parent) */
  .tt-main-menu-list > li a:hover,
  .tt-main-menu-list > li > .tt-submenu-trigger:hover a,
  .tt-main-menu-list > li > .tt-submenu-trigger:hover .tt-m-caret,
  .tt-main-menu-list > li.active > a,
  .tt-main-menu-list > li.active > .tt-submenu-trigger a,
  .tt-main-menu-list > li.active > .tt-submenu-trigger .tt-m-caret {
    color: #fff;
  }

  /* Mobile submenu */
  .tt-submenu-wrap {
    position: relative;
  }
  .tt-submenu-trigger {
    position: relative;
    display: inline-block;
  }

  .tt-submenu-trigger > a {
    position: relative;
    z-index: 1;
  }
  .tt-submenu-trigger .tt-submenu-trigger-m {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
  }

  .tt-submenu {
    display: none;
    position: relative;
    top: auto;
    left: 0 !important;
    min-width: 100%;
    background-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #a9a9a9;
  }

  .tt-submenu-list {
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
  }
  .tt-submenu-list .tt-submenu {
    margin-top: 10px;
  }
  .tt-submenu-list > li {
  }
  .tt-submenu-list > li a {
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: normal;
    color: #aaa;
    -webkit-text-stroke: 0;
  }

  /* Mobile submenu list hover/active */
  .tt-submenu-list > li a:hover,
  .tt-submenu-list > li > .tt-submenu-trigger:hover a,
  .tt-submenu-list > li > .tt-submenu-trigger:hover .tt-m-caret,
  .tt-submenu-list > li > .tt-submenu-trigger.tt-m-submenu-open a,
  .tt-submenu-list > li > .tt-submenu-trigger.tt-m-submenu-open .tt-m-caret,
  .tt-submenu-list > li.active > a,
  .tt-submenu-list > li.active > .tt-submenu-trigger a,
  .tt-submenu-list > li.active > .tt-submenu-trigger .tt-m-caret {
    color: #fff;
  }

  /* Mobile submenu caret (requires FontAwesome: https://fontawesome.com/) */
  .tt-m-caret {
    position: absolute;
    top: 55%;
    right: -45px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: #eaeaea;
    cursor: pointer;
    z-index: 9;
    border-radius: 100%;
    transform: translate3d(0, -50%, 0);
  }
  .tt-m-caret::after {
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    transition: all 0.2s ease-in-out;
  }
  .tt-submenu-trigger.tt-m-submenu-open .tt-m-caret::after {
    transform: rotate(180deg);
  }

  /* Mobile sub-submenu caret */
  .tt-submenu-list > li > .tt-submenu-trigger .tt-m-caret {
    color: #8a8a8a;
  }

  /* Mobile menu toggle button */
  #tt-m-menu-toggle-btn-wrap {
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: initial;
    cursor: pointer;
    z-index: 9;
  }
  .tt-m-menu-toggle-btn-holder {
    float: left;
  }

  .tt-m-menu-toggle-btn {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
  }
  .tt-m-menu-toggle-btn span {
    position: absolute;
    display: block;
    top: 52%;
    left: 50%;
    height: 2px;
    width: 24px;
    background-color: transparent;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }
  .tt-m-menu-toggle-btn span::before,
  .tt-m-menu-toggle-btn span::after {
    position: absolute;
    display: block;
    content: "";
    height: 2px;
    width: 24px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }
  .tt-m-menu-toggle-btn span::before {
    top: -4px;
    width: 24px;
  }
  .tt-m-menu-toggle-btn span::after {
    top: auto;
    bottom: -4px;
    width: 18px;
  }

  /* Toggle button text */
  .tt-m-menu-toggle-btn-text {
    float: left;
    padding-right: 5px;
    overflow: hidden;
    text-align: right;
    font-size: 16px;
  }
  body.tt-m-menu-open .tt-m-menu-toggle-btn-text .tt-m-menu-text-menu {
    display: none;
  }

  /* Toggle button close */
  body.tt-m-menu-open .tt-m-menu-toggle-btn span {
    width: 20px;
    background-color: transparent;
  }
  body.tt-m-menu-open .tt-m-menu-toggle-btn span::before {
    top: 0;
    width: 20px;
    transform: rotate(45deg);
  }
  body.tt-m-menu-open .tt-m-menu-toggle-btn span::after {
    bottom: 0;
    width: 20px;
    transform: rotate(-45deg);
  }

  .tt-m-menu-toggle-btn-text .tt-m-menu-text-close {
    display: none;
  }
  body.tt-m-menu-open .tt-m-menu-toggle-btn-text .tt-m-menu-text-close {
    display: block;
  }

  /* Disable menu toggle button click until the animations last */
  body.tt-m-menu-toggle-no-click #tt-m-menu-toggle-btn-wrap {
    pointer-events: none;
  }

  /* Align mobile menu to center */
  .tt-main-menu.tt-m-menu-center .tt-main-menu-content {
    text-align: center;
  }
  .tt-main-menu.tt-m-menu-center .tt-submenu-list {
    margin: 0;
  }
}

/* ------------------------------------------------------------- *
   * Overlay menu 
  /* ------------------------------------------------------------- */

/* Overlay menu 
  ================ */
.tt-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0a0a0a;
  visibility: hidden;
  opacity: 0;
  pointer-events: initial;
}
.tt-ol-menu-holder {
  position: relative;
  height: 100%;
  width: calc(100% + 17px);
  padding-right: 17px;
  overflow-y: scroll;
  z-index: 2;
}
.tt-ol-menu-inner {
  display: table;
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.tt-ol-menu-content {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 120px 5% 120px 5%;
}
@media (max-width: 767px) {
  .tt-ol-menu-content {
    padding-top: 25%;
    padding-bottom: 35%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Disable page scroll if overlay menu is open */
body:not(.tt-smooth-scroll).tt-ol-menu-open {
  overflow-y: hidden;
}

/* Hide smooth scrollbar if overlay menu is open */
body.tt-smooth-scroll.tt-ol-menu-open .scrollbar-track {
  display: none !important;
}

/* Overlay menu list */
.tt-ol-menu-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.tt-ol-menu-list > li {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .tt-ol-menu-list > li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.tt-ol-menu-list > li:first-child {
  margin-top: 0;
}
.tt-ol-menu-list > li:last-child {
  margin-bottom: 0;
}

/* Overlay menu list item counter */
@media (min-width: 992px) {
  .tt-overlay-menu.tt-ol-menu-count .tt-ol-menu-list {
    counter-reset: ol-menu-list-counter;
  }
  .tt-overlay-menu.tt-ol-menu-count .tt-ol-menu-list > li > a::before,
  .tt-overlay-menu.tt-ol-menu-count
    .tt-ol-menu-list
    > li
    > .tt-ol-submenu-trigger
    > a::before {
    position: absolute;
    counter-increment: ol-menu-list-counter;
    content: "" counter(ol-menu-list-counter, decimal-leading-zero);
    top: 5px;
    left: -30px;
    line-height: 1;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    opacity: 0.4;
  }
}

/* Overlay menu list links (master parent) */
.tt-ol-menu-list > li > a,
.tt-ol-menu-list > li > .tt-ol-submenu-trigger > a {
  position: relative;
  display: inline-block;
  font-size: clamp(34px, 3.2vw, 62px);
  font-weight: bold;
  font-weight: 600;
  line-height: 1.2;
  color: #eaeaea;
  transition: opacity 0.3s ease-in-out;
}

/* Overlay menu list hover/active (master parent). Note: no effect on mobile devices! */
body:not(.is-mobile) .tt-ol-menu-list.tt-ol-menu-hover > li > a,
body:not(.is-mobile)
  .tt-ol-menu-list.tt-ol-menu-hover
  > li
  > .tt-ol-submenu-trigger
  > a,
body:not(.is-mobile)
  .tt-ol-menu-list.tt-ol-menu-hover
  > li
  > .tt-ol-submenu-trigger
  .tt-ol-submenu-caret {
  opacity: 0.5;
}
body:not(.is-mobile) .tt-ol-menu-list > li.active > a,
body:not(.is-mobile) .tt-ol-menu-list > li.active > .tt-ol-submenu-trigger > a,
body:not(.is-mobile)
  .tt-ol-menu-list
  > li.active
  > .tt-ol-submenu-trigger
  .tt-ol-submenu-caret,
body:not(.is-mobile) .tt-ol-menu-list > li > a:hover,
body:not(.is-mobile) .tt-ol-menu-list > li > .tt-ol-submenu-trigger:hover > a,
body:not(.is-mobile)
  .tt-ol-menu-list
  > li
  > .tt-ol-submenu-trigger:hover
  .tt-ol-submenu-caret,
body:not(.is-mobile)
  .tt-ol-menu-list
  > li
  > .tt-ol-submenu-trigger.tt-ol-submenu-open
  > a,
body:not(.is-mobile)
  .tt-ol-menu-list
  > li
  > .tt-ol-submenu-trigger.tt-ol-submenu-open
  .tt-ol-submenu-caret {
  opacity: 1;
}

/* Overlay menu submenu */
.tt-ol-submenu-wrap {
  position: relative;
}
.tt-ol-submenu-trigger {
  position: relative;
  display: inline-block;
}
.tt-ol-submenu {
  display: none;
  position: relative;
  height: auto;
  margin-left: 20px;
  color: #8a8a8a;
}
.tt-ol-menu-list > li > .tt-ol-submenu {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Overlay menu submenu list */
.tt-ol-submenu-list {
  padding: 0;
  list-style: none;
}
.tt-ol-submenu-list .tt-ol-submenu {
  margin-top: 15px;
  margin-bottom: 20px;
}

/* Overlay menu submenu list links */
.tt-ol-submenu-list > li a,
.tt-ol-submenu-list > li > .tt-ol-submenu-trigger > a {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 24px;
  color: #aaa;
  transition: color 0.3s ease-in-out;
}

/* Overlay menu submenu list hover/active */
.tt-ol-submenu-list > li > .tt-ol-submenu-trigger.tt-ol-submenu-open > a,
.tt-ol-submenu-list
  > li
  > .tt-ol-submenu-trigger.tt-ol-submenu-open
  .tt-ol-submenu-caret,
.tt-ol-submenu-list > li.active > a,
.tt-ol-submenu-list > li.active > .tt-ol-submenu-trigger > a,
.tt-ol-submenu-list > li.active > .tt-ol-submenu-trigger .tt-ol-submenu-caret,
.tt-ol-submenu-list > li a:hover,
.tt-ol-submenu-list > li > .tt-ol-submenu-trigger:hover > a,
.tt-ol-submenu-list > li > .tt-ol-submenu-trigger:hover .tt-ol-submenu-caret {
  color: #fff;
}

/* Submenu caret (requires FontAwesome: https://fontawesome.com/) */
.tt-ol-submenu-caret-wrap {
  position: absolute;
  top: 60%;
  right: -40px;
  transform: translate3d(0, -50%, 0);
}
@media (max-width: 767px) {
  .tt-ol-submenu-caret-wrap {
    right: -40px;
  }
}
.tt-ol-submenu-caret {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #eaeaea;
  cursor: pointer;
  z-index: 2;
  border-radius: 100%;
  transition: opacity 0.3s ease-in-out;
}
.tt-ol-submenu-caret::after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  transition: all 0.2s ease-in-out;
}
.tt-ol-submenu-trigger.tt-ol-submenu-open .tt-ol-submenu-caret::after {
  transform: rotate(180deg);
}

/* Sub-submenu caret */
.tt-ol-menu-list > li li .tt-ol-submenu-caret-wrap {
  top: 50%;
  right: -40px;
}
.tt-ol-menu-list > li li .tt-ol-submenu-caret {
  font-size: 16px;
  color: #8a8a8a;
}

/* Overlay menu toggle button 
  ============================== */
#tt-ol-menu-toggle-btn-wrap {
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: initial;
  cursor: pointer;
  z-index: 9;
}
.tt-ol-menu-toggle-btn-holder {
  float: left;
}

/* Toggle button */
.tt-ol-menu-toggle-btn {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
}
.tt-ol-menu-toggle-btn span {
  position: absolute;
  display: block;
  top: 54%;
  left: 50%;
  height: 2px;
  width: 24px;
  background-color: transparent;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}
.tt-ol-menu-toggle-btn span::before,
.tt-ol-menu-toggle-btn span::after {
  position: absolute;
  display: block;
  content: "";
  height: 5px;
  width: 35px;
  background-image: linear-gradient(to right, #eace00, #8bd4ef);
  transition: all 0.3s ease-in-out;
}
.tt-ol-menu-toggle-btn span::before {
  top: -4px;
  width: 35px;
}
.tt-ol-menu-toggle-btn span::after {
  top: auto;
  bottom: -9px;
  width: 18px;
}

/* Toggle button text */
.tt-ol-menu-toggle-btn-text {
  float: left;
  padding-right: 5px;
  overflow: hidden;
  text-align: right;
  font-size: 15px;
  color: #fff;
}

/* Toggle button text hover */
.tt-ol-menu-toggle-btn-text .text-menu {
  position: relative;
  display: inline-block;
  text-align: right;
  transition: transform 0.3s;
  background: linear-gradient(to right, #eace00 20%, #8bd4ef 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 34px;
}
body.tt-ol-menu-open .tt-ol-menu-toggle-btn-text .text-menu {
  display: none;
}

body:not(.is-mobile) .tt-ol-menu-toggle-btn-text .text-menu::before {
  position: absolute;
  top: 100%;
  right: 0;
  content: attr(data-hover);
}
body:not(.is-mobile)
  #tt-ol-menu-toggle-btn-wrap:hover
  .tt-ol-menu-toggle-btn-text
  .text-menu {
  transform: translate3d(0, -100%, 0);
}

/* Toggle button close */
body.tt-ol-menu-open .tt-ol-menu-toggle-btn span {
  width: 20px;
  background-color: transparent;
}
body.tt-ol-menu-open .tt-ol-menu-toggle-btn span::before {
  top: 0;
  width: 20px;
  transform: rotate(45deg);
}
body.tt-ol-menu-open .tt-ol-menu-toggle-btn span::after {
  bottom: 0;
  width: 20px;
  transform: rotate(-45deg);
}

.tt-ol-menu-toggle-btn-text .text-close {
  display: none;
}
body.tt-ol-menu-open .tt-ol-menu-toggle-btn-text .text-close {
  display: block;
}

/* Disable menu toggle button click until the animations last */
body.olm-toggle-no-click .tt-ol-menu-toggle-btn-text,
body.olm-toggle-no-click .tt-ol-menu-toggle-btn {
  pointer-events: none;
}

/* Overlay menu position center 
  ================================ */
.tt-overlay-menu.tt-ol-menu-center .tt-ol-menu-content {
  text-align: center;
}
.tt-overlay-menu.tt-ol-menu-center .tt-ol-submenu {
  margin-left: 0;
}

/* ------------------------------------------------------------- *
   * Page header
  /* ------------------------------------------------------------- */

#page-header {
  position: relative;
  z-index: 1;
}
.page-header-inner,
body.ph-image-on.ph-bg-image-on
  #page-header:not(.project-info-list-on)
  .page-header-inner {
  width: 100%;
  padding-top: 13vw;
  padding-bottom: 7vw;
  z-index: 2;
}
body.ph-image-on #page-header:not(.project-info-list-on) .page-header-inner {
  padding-top: 16vw;
  padding-bottom: 13vw;
}
body:not(.tt-boxed) .page-header-inner,
.page-header-inner:not(.tt-wrap) {
  padding-left: 13%;
  padding-right: 13%;
}
@media (max-width: 1920px) {
  .page-header-inner {
    padding-top: 250px;
    padding-bottom: 140px;
  }
  body.ph-image-on #page-header:not(.project-info-list-on) .page-header-inner {
    padding-top: 300px;
    padding-bottom: 260px;
  }

  /* If page header image does not exist or project info list exist */
  body:not(.ph-image-on) #page-header .page-header-inner,
  body.ph-image-on.ph-bg-image-on
    #page-header:not(.project-info-list-on)
    .page-header-inner {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}
@media (max-width: 1400px) {
  body.tt-boxed .page-header-inner.tt-wrap {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (max-width: 1024px) {
  #page-header:not(.project-info-list-on) .page-header-inner {
    padding-top: 250px;
    padding-bottom: 250px;
  }
  body:not(.tt-boxed) .page-header-inner,
  .page-header-inner:not(.tt-wrap) {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 768px) {
  .page-header-inner,
  body:not(.ph-image-on) #page-header .page-header-inner,
  body.ph-image-on.ph-bg-image-on
    #page-header:not(.project-info-list-on)
    .page-header-inner {
    padding-top: 200px;
    padding-bottom: 80px;
  }
}
@media (max-width: 540px) {
  body.ph-image-on #page-header:not(.project-info-list-on) .page-header-inner {
    padding-top: 240px;
    padding-bottom: 40vw;
  }
}

/* Page header image 
  ===================== */
.ph-image {
  position: absolute;
  top: 160px;
  right: 18vw;
  width: clamp(340px, 18vw, 800px);
  z-index: 2;
  line-height: 0;
}
@media (max-width: 1920px) {
  .ph-image {
    width: 380px;
  }
  #page-header.ph-full:not(.ph-center):not(.ph-bg-image) .ph-image {
    width: 480px;
  }
}
@media (max-width: 1600px) {
  .ph-image,
  #page-header.ph-full:not(.ph-center):not(.ph-bg-image) .ph-image {
    width: 320px;
  }
}
@media (max-width: 1024px) {
  .ph-image,
  #page-header.ph-full:not(.ph-center):not(.ph-bg-image) .ph-image {
    right: 10vw;
  }
}
@media (max-width: 540px) {
  .ph-image,
  #page-header.ph-full:not(.ph-center):not(.ph-bg-image) .ph-image {
    width: 50%;
  }
}

.ph-image-inner {
  position: relative;
  width: 100%;
}

/* Video */
#page-header:not(.ph-image-cropped) .ph-video-wrap {
  position: relative;
  padding-bottom: 140%;
}
.ph-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

#page-header.ph-bg-image .ph-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Page header image cover opasity */
[class*="ph-image-cover-"] .ph-image-inner::before {
  position: absolute;
  display: block;
  content: "";
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background-color: #040404;
  z-index: 1;
  opacity: 0;
}
.ph-image-cover-0 .ph-image-inner::before {
  opacity: 0;
}
.ph-image-cover-0-5 .ph-image-inner::before {
  opacity: 0.05;
}
.ph-image-cover-1 .ph-image-inner::before {
  opacity: 0.1;
}
.ph-image-cover-1-5 .ph-image-inner::before {
  opacity: 0.15;
}
.ph-image-cover-2 .ph-image-inner::before {
  opacity: 0.2;
}
.ph-image-cover-2-5 .ph-image-inner::before {
  opacity: 0.25;
}
.ph-image-cover-3 .ph-image-inner::before {
  opacity: 0.3;
}
.ph-image-cover-3-5 .ph-image-inner::before {
  opacity: 0.35;
}
.ph-image-cover-4 .ph-image-inner::before {
  opacity: 0.4;
}
.ph-image-cover-4-5 .ph-image-inner::before {
  opacity: 0.45;
}
.ph-image-cover-5 .ph-image-inner::before {
  opacity: 0.5;
}
.ph-image-cover-5-5 .ph-image-inner::before {
  opacity: 0.55;
}
.ph-image-cover-6 .ph-image-inner::before {
  opacity: 0.6;
}
.ph-image-cover-6-5 .ph-image-inner::before {
  opacity: 0.65;
}
.ph-image-cover-7 .ph-image-inner::before {
  opacity: 0.7;
}
.ph-image-cover-7-5 .ph-image-inner::before {
  opacity: 0.75;
}
.ph-image-cover-8 .ph-image-inner::before {
  opacity: 0.8;
}
.ph-image-cover-8-5 .ph-image-inner::before {
  opacity: 0.85;
}
.ph-image-cover-9 .ph-image-inner::before {
  opacity: 0.9;
}
.ph-image-cover-9-5 .ph-image-inner::before {
  opacity: 0.95;
}

/* if class "ph-image-cropped" enabled (not for background image!) */
#page-header:not(.ph-bg-image).ph-image-cropped .ph-image-inner {
  position: relative;
  padding-bottom: 130%;
}
#page-header:not(.ph-bg-image).ph-image-cropped .ph-image-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Make image to background image */
#page-header.ph-bg-image .ph-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  z-index: -1;
  transform: none;
  overflow: hidden;
}
#page-header.ph-bg-image.ph-image-shadow:after {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 35vh;
  z-index: 3;
  pointer-events: none;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#0d0d0d)
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #0d0d0d 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#0d0d0d',GradientType=0 );
}
#page-header.ph-bg-image .ph-image-inner {
  width: 100%;
  height: 100%;
}

#page-header.ph-bg-image .ph-image img {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

#page-header.ph-bg-image .ph-video-wrap {
  padding-bottom: 0;
}

/* Page header caption 
  ======================= */
.ph-caption {
  position: relative;
}

/* page header caption title */
.ph-caption-title {
  position: relative;
  margin: 0;
  font-size: clamp(40px, 5vw, 94px);
  font-weight: bold;
  font-weight: 500;
  text-shadow: 8px 9px 31px #8bd4ef70;
  line-height: 1.3;
  z-index: 9;
}

/* page header caption title ghost */
.ph-caption-title-ghost {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: -6%;
  padding-top: 8%;
  font-size: calc(16px + 9vw);
  font-weight: bold;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  line-height: 1;
  opacity: 0.04;
  z-index: 1;
}

/* page header caption subtitle */
.ph-caption-subtitle {
  position: relative;
  margin-top: 40px;
  font-size: calc(15px + 0.5vw);
  font-weight: 600;
  color: #e4e4e4;
  z-index: 9;
}
#page-header:not(.ph-center):not(.ph-bg-image) .ph-caption-subtitle {
  color: #777;
}

/* page header caption category */
.ph-categories {
  position: relative;
  margin-bottom: 15px;
  z-index: 9;
}
.ph-category {
  position: relative;
  display: inline-block;
  font-size: calc(15px + 0.1vw);
  color: #fff;
  line-height: 1.4;
}
a.ph-category:hover {
  opacity: 0.8;
}
.ph-category ~ .ph-category:not(:empty)::before {
  content: ", ";
  margin-left: -4px;
}

/* page header caption meta */
.ph-meta {
  position: relative;
  display: inline-flex;
  margin-top: 40px;
  font-size: 16px;
  color: #cacaca;
  font-weight: normal;
  z-index: 9;
}
.ph-meta-published {
}
.ph-meta-posted-by {
  margin-left: 10px;
  font-weight: normal;
}
.ph-meta-posted-by a {
  position: relative;
  color: #cacaca;
}
.ph-meta-posted-by a:hover {
  opacity: 0.8;
}

/* page header caption description */
.ph-caption-description {
  position: relative;
  max-width: 900px;
  margin-top: 50px;
  z-index: 9;
}

/* page header caption paragraph */
.ph-caption > p {
  position: relative;
  max-width: 900px;
  z-index: 9;
}
.ph-caption .ph-caption-title + p,
.ph-caption .ph-caption-title-ghost + p,
.ph-caption .ph-caption-subtitle + p {
  margin-top: 50px;
}
.ph-caption > p:last-child {
  margin-bottom: 0;
}

/* Project info list
  ===================== */
.project-info-list {
  position: relative;
  z-index: 9;
}

#page-header .project-info-list {
  margin-top: 60px;
}

.project-info-list > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.project-info-list > ul > li {
  position: relative;
  display: inline-block;
  max-width: 320px;
  vertical-align: top;
  padding: 0px 30px 0 0;
  margin: 15px 25px 15px 0;
}
@media (max-width: 768px) {
  #page-header:not(.ph-center) .project-info-list > ul > li {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 15px 0 25px 0;
  }
}
.project-info-list > ul > li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.project-info-list > ul > li::after {
  position: absolute;
  display: block;
  content: "";
  top: 24px;
  right: 0;
  width: 1px;
  height: 60px;
  margin-top: -18px;
  background-color: rgb(255 255 255 / 40%);
}
.project-info-list > ul > li:last-child::after {
  display: none;
}
@media (max-width: 768px) {
  #page-header:not(.ph-center) .project-info-list > ul > li::after {
    display: none;
  }
}

/* project info list if align senter */
.text-center .project-info-list > ul > li,
.project-info-list.text-center > ul > li {
  padding: 0px 40px 0 30px;
  margin: 15px 0 15px 0;
}

/* project info list heading */
.project-info-list .pi-list-heading {
  margin-bottom: 5px;
  font-size: calc(17px + 0.1vw);
  font-weight: normal;
  opacity: 0.7;
}

/* project info list content */
.project-info-list .pi-list-cont {
  font-size: calc(17px + 0.1vw);
}
.project-info-list .pi-list-cont a {
  word-wrap: break-word;
  color: #fff;
  font-size: calc(17px + 0.1vw);
}
.project-info-list .pi-list-cont a:hover {
  opacity: 0.8;
}

.project-info-list .social-buttons {
  display: inline-block;
  margin-top: -5px;
  margin-left: -10px;
}
.project-info-list .social-buttons > ul > li {
  margin-right: 15px;
}
.project-info-list .social-buttons > ul > li a {
  width: 45px;
  height: 45px;
  font-size: 15px;
}

/* project info list icon */
.project-info-list .pi-list-icon {
  position: relative;
  display: inline-block;
  bottom: 2px;
  margin-left: 10px;
  font-size: 13px;
  transform: rotate(-45deg);
}

/* Scroll down circle 
  ====================== */
.scroll-down-circle {
  position: absolute;
  display: block;
  left: 3%;
  bottom: -100px;
  width: 250px;
  height: 250px;
  z-index: 9;
}
@media (max-width: 1024px) {
  .scroll-down-circle {
    display: none;
  }
}
#page-header:not(.ph-full) .scroll-down-circle {
  /* Display only on full scren page header! */
  display: none;
}

/* Scroll down circle icon */
.scroll-down-circle .sdc-icon {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  font-size: 16px;
  color: #999;
  transform: translate(-50%, -50%);
}
#page-header.ph-bg-image .scroll-down-circle .sdc-icon {
  color: #fff;
}

/* Scroll down circle svg */
.scroll-down-circle svg {
  width: 100%;
  animation: sdc-rotation 8s infinite linear;
}
.scroll-down-circle rect {
  fill: #fff;
}
.scroll-down-circle text {
  font-size: 33px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 25px;
  fill: #bbb;
  transition: fill 0.2s;
}
.scroll-down-circle:hover text {
  fill: #fff;
}
#page-header.ph-bg-image .scroll-down-circle text {
}

/* Scroll down circle animation */
@keyframes sdc-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* Made with love 
  ================== */
.made-with-love {
  position: absolute;
  display: none; /* disabled by default */
  right: 3.5%;
  bottom: 50px;
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
  z-index: 9;
}
#page-header.ph-full .made-with-love,
#page-header.ph-bg-image .made-with-love {
  display: block; /* enable only with "ph-full" */
}
@media (max-width: 1024px) {
  .made-with-love {
    display: none;
  }
}
.mwl-inner {
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  transform-origin: 50% -70%;
}
.mwl-text {
  display: inline-block;
}
.mwl-icon {
  display: inline-block;
  margin-left: 8px;
  animation: animateHeart 2.5s infinite;
}
@keyframes animateHeart {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.1);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

/* Page header share 
  ===================== */
.ph-share {
  position: fixed;
  display: inline-block;
  bottom: 5%;
  right: 3.5%;
  z-index: 9;
  cursor: pointer;
}

.ph-share-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ph-share-text {
  display: inline-block;
  font-size: 14px;
  color: #fff;
}
.ph-share-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 17px;
  color: #fff;
  border-radius: 50px;
}

@media (min-width: 1025px) {
  .ph-share {
    position: absolute;
  }
  body.made-with-love-on .ph-share {
    bottom: 170px;
  }
  .ph-share-text {
    margin-right: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    transform: translate3d(10px, 0, 0);
  }
  .ph-share.active .ph-share-text {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.2s;
  }

  .ph-share-icon {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }
  .ph-share.active .ph-share-icon {
    opacity: 1;
  }
}
@media (max-width: 1024px) {
  .ph-share {
    bottom: 10px;
    right: 10px;
  }
  .ph-share-trigger {
    background-color: rgb(10 10 10 / 70%);
    padding: 0 5px 0 10px;
  }
  .ph-share-text {
    font-size: 15px;
  }
  .ph-share-icon {
    width: 30px;
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .ph-share {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .ph-share-trigger {
    background-color: rgb(10 10 10 / 80%);
    padding: 0 10px;
  }
}

/* Page header share content */
.ph-share-content {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  visibility: hidden;
  opacity: 0;
  z-index: 998;
}
.ph-share-close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.ph-share-ghost {
  position: absolute;
  top: 0;
  bottom: -3.5%;
  display: flex;
  align-items: flex-end;
  font-size: calc(28px + 34vw);
  font-weight: bold;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  line-height: 1;
  z-index: 1;
  opacity: 0.03;
}

.ph-share-inner {
  position: relative;
  padding: 3%;
  text-align: center;
  z-index: 3;
}
@media (max-width: 1024px) {
  .ph-share-inner {
    width: 100%;
  }
}

.ph-share-title {
  margin-bottom: 15px;
  font-size: clamp(48px, 4vw, 62px);
  font-weight: 600;
}
.ph-share-subtitle {
  font-size: 19px;
  font-weight: 300;
  color: #c1c1c1;
}
.ph-share-content .social-buttons {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .ph-share-content .social-buttons {
    margin-top: 20px;
  }
}
.ph-share-content .social-buttons > ul > li a {
  font-size: 19px;
}

.ph-share-m-close {
  margin-top: 80px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
}
body:not(.is-mobile) .ph-share-m-close {
  display: none;
}

/* Page header styles 
  ====================== */
/* page header caption title (stroke style) */
#page-header.ph-stroke .ph-caption-title {
  font-weight: bold;
  font-weight: 800;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: transparent;
}

/* Page header sizes 
  ===================== */
/* Caption size sm */
#page-header.ph-cap-sm .ph-caption-title {
  font-size: clamp(40px, 4vw, 68px);
}
#page-header.ph-cap-sm .ph-caption-title-ghost {
  padding-top: 3%;
  font-size: calc(16px + 8vw);
}

/* Caption size lg */
#page-header.ph-cap-lg .ph-caption-title {
  font-size: clamp(48px, 6vw, 112px);
}
#page-header.ph-cap-lg .ph-caption-title-ghost {
  padding-top: 5%;
  font-size: calc(16px + 10vw);
}

/* Caption size xlg */
#page-header.ph-cap-xlg .ph-caption-title {
  font-size: clamp(48px, 8vw, 142px);
}
@media (min-width: 1025px) {
  #page-header.ph-cap-xlg.ph-stroke .ph-caption-title {
    -webkit-text-stroke-width: 3px;
  }
}
#page-header.ph-cap-xlg .ph-caption-title-ghost {
  padding-top: 5%;
  font-size: calc(16px + 10vw);
}

/* Caption size xxlg */
#page-header.ph-cap-xxlg .ph-caption-title {
  font-size: clamp(48px, 10vw, 273px);
  /* margin-top: 116px; */
}
@media (min-width: 1025px) {
  #page-header.ph-cap-xxlg.ph-stroke .ph-caption-title {
    -webkit-text-stroke-width: 3px;
  }
}
#page-header.ph-cap-xxlg .ph-caption-title-ghost {
  padding-top: 5%;
  font-size: calc(16px + 13vw);
}

/* Page header full screen */
@media (min-width: 1025px) {
  #page-header.ph-full {
    display: flex;
    align-items: center;
    min-height: 100vh;
  }
  #page-header.ph-full .page-header-inner {
    padding-top: 5% !important;
    padding-bottom: 5% !important;
  }
  #page-header.ph-full .ph-image {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    height: 100%;
  }
  #page-header.ph-full:not(.ph-bg-image).ph-image-cropped .ph-image-inner {
    padding-bottom: 140%;
  }
}

/* Page header positions 
  ========================= */
/* Position center */
#page-header.ph-center {
  text-align: center;
}
@media (min-width: 1025px) {
  #page-header.ph-center .page-header-inner {
    max-width: 100%;
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
}
#page-header.ph-center .ph-caption {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#page-header.ph-center .ph-caption-title-ghost {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
#page-header.ph-center .ph-caption-description,
#page-header.ph-center .ph-caption > p {
  margin-left: auto;
  margin-right: auto;
}

#page-header.ph-center:not(.ph-bg-image) .ph-image {
  right: 50%;
  transform: translate(50%, 0);
}
#page-header.ph-center .project-info-list .social-buttons {
  margin-left: 0;
}

/* Position inline (for a single project page only! No effect with class "ph-center"!) */
@media (min-width: 1025px) {
  #page-header:not(.ph-center).ph-inline .page-header-inner {
    display: flex;
  }
  #page-header:not(.ph-center).ph-inline .ph-caption {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    align-self: center;
    padding-right: 10%;
  }

  #page-header:not(.ph-center).ph-inline .project-info-list {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    align-self: center;
    margin: 0;
  }

  #page-header:not(.ph-center).ph-inline .project-info-list > ul > li {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 0 25px 0;
  }
  #page-header:not(.ph-center).ph-inline
    .project-info-list
    > ul
    > li:last-child {
    margin: 0;
  }
  #page-header:not(.ph-center).ph-inline .project-info-list > ul > li::after {
    display: none;
  }
}

/* If page header background image is light (class "ph-bg-image-is-light")
  ============================================ */
/* Make page header caption dark */
body.ph-bg-image-light-on .ph-category,
body.ph-bg-image-light-on .ph-caption-title,
body.ph-bg-image-light-on .ph-caption-subtitle {
  color: #111;
}
body.ph-bg-image-light-on #page-header.ph-stroke .ph-caption-title {
  -webkit-text-stroke-color: #111;
}

/* Make page header project info list dark */
body.ph-bg-image-light-on #page-header .project-info-list,
body.ph-bg-image-light-on #page-header .project-info-list .pi-list-cont a {
  color: #111;
  font-weight: 500;
}
body.ph-bg-image-light-on #page-header .project-info-list > ul > li::after {
  background-color: rgb(92 92 92 / 40%);
}

/* Make magic cursor dark */
body.ph-bg-image-light-on:not(.tt-ol-menu-open):not(.ph-share-open) #ball {
  border-color: #666;
}

/* Make header logo dark */
body.ph-bg-image-light-on #tt-header .tt-logo-light {
  display: none;
}
body.ph-bg-image-light-on #tt-header .tt-logo-dark {
  display: block;
}

/* Make overlay menu toggle button dark */
body.ph-bg-image-light-on:not(.tt-ol-menu-open) .tt-ol-menu-toggle-btn-text {
  color: #111;
}
body.ph-bg-image-light-on:not(.tt-ol-menu-open)
  .tt-ol-menu-toggle-btn
  span::before,
body.ph-bg-image-light-on:not(.tt-ol-menu-open)
  .tt-ol-menu-toggle-btn
  span::after {
  background-color: #111;
}

/* Make classic menu links dark */
@media (min-width: 1025px) {
  body.ph-bg-image-light-on .tt-main-menu-list > li > a,
  body.ph-bg-image-light-on .tt-main-menu-list > li > .tt-submenu-trigger > a {
    color: #111;
  }
}
@media (max-width: 1024px) {
  body.ph-bg-image-light-on:not(.tt-m-menu-open) .tt-m-menu-toggle-btn-text {
    color: #111;
  }
  body.ph-bg-image-light-on:not(.tt-m-menu-open)
    .tt-m-menu-toggle-btn
    span::before,
  body.ph-bg-image-light-on:not(.tt-m-menu-open)
    .tt-m-menu-toggle-btn
    span::after {
    background-color: #111;
  }
}

/* Make scroll down circle dark */
body.ph-bg-image-light-on
  #page-header:not(.ph-image-shadow)
  .scroll-down-circle
  text {
  fill: #111;
}
body.ph-bg-image-light-on
  #page-header:not(.ph-image-shadow)
  .scroll-down-circle
  .sdc-icon {
  color: #555;
}

/* Make made with love dark */
body.ph-bg-image-light-on #page-header:not(.ph-image-shadow) .made-with-love {
  color: #111;
  opacity: 1 !important;
}

/* Make project share button dark */
body.ph-bg-image-light-on #page-header:not(.ph-image-shadow) .ph-share-icon {
  color: #111;
  opacity: 0.9 !important;
}
body.ph-bg-image-light-on #page-header:not(.ph-image-shadow) .ph-share-text {
  color: #111;
}

/* ------------------------------------------------------------- *
   * tt-Grid
  /* ------------------------------------------------------------- */

.tt-grid {
  position: relative;
}
.tt-grid::after {
  content: "";
  display: table;
  clear: both;
}

/* tt-Grid top content
  ======================= */
.tt-grid-top {
  position: relative;
  padding-bottom: 40px;
  text-align: right;
}
body:not(.tt-boxed)
  .tt-section-inner.tt-wrap
  .tt-grid:not([class*="ttgr-gap-"])
  .tt-grid-top,
.tt-section-inner:not(.tt-wrap)
  .tt-grid:not([class*="ttgr-gap-"])
  .tt-grid-top {
  padding-left: 3vw;
  padding-right: 3vw;
}

/* tt-Ggrid categories/filter 
  ============================== */
.tt-grid-categories {
  position: relative;
}

/* tt-Ggrid categories/filter trigger 
  ====================================== */
.ttgr-cat-trigger-wrap {
  position: relative;
  padding-left: 15px;
  font-size: 16px;
  color: #fff;
  z-index: 999;
}

.ttgr-cat-trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ttgr-cat-trigger:hover {
}

.ttgr-cat-text {
  float: left;
  min-width: 60px;
  text-align: right;
  overflow: hidden;
}
.ttgr-cat-text > span {
  position: relative;
  display: inline-block;
  width: 100%;
  transition: transform 0.3s;
}
body:not(.is-mobile) .ttgr-cat-trigger-wrap:hover .ttgr-cat-text > span {
  transform: translate3d(0, -100%, 0);
}
body:not(.is-mobile) .ttgr-cat-text > span::before {
  position: absolute;
  top: 100%;
  right: 0;
  content: attr(data-hover);
}

.ttgr-cat-icon {
  float: left;
}
.ttgr-cat-icon span {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 17px;
}

/* If "ttgr-cat-nav" is open */
@media (min-width: 768px) {
  body.ttgr-cat-nav-open .ttgr-item-inner {
    transition: transform 0.6s;
  }
}

/* tt-Ggrid categories trigger position fixed */
.ttgr-cat-trigger-wrap.ttgr-cat-fixed {
  position: fixed;
  bottom: 5%;
  right: 4%;
  padding: 10px 0 10px 10px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

/* tt-Ggrid categories/filter nav 
  ================================== */
.ttgr-cat-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
  pointer-events: initial;
  z-index: 998;
  visibility: hidden;
  opacity: 0;
}
.ttgr-cat-list-holder {
  position: relative;
  height: 100%;
  width: calc(100% + 17px);
  padding-right: 17px;
  overflow-y: scroll;
  z-index: 2;
}
.ttgr-cat-list-inner {
  display: table;
  width: 100%;
  max-width: 1282px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.ttgr-cat-list-content {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 160px 5% 120px 10%;
}

/* tt-Ggrid categories list */
.ttgr-cat-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ttgr-cat-list > li {
  position: relative;
}
.ttgr-cat-list > li > a {
  position: relative;
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: calc(26px + 1.2vw);
  font-weight: bold;
  font-weight: 700;
  line-height: 1.2;
  color: #bbb;
  transition: color 0.3s ease-in-out;
}
.ttgr-cat-list > li > a:hover,
.ttgr-cat-list > li > a:focus,
.ttgr-cat-list > li > a.active {
  color: #fff;
}
.ttgr-cat-list > li:first-child > a {
  padding-top: 0;
}
.ttgr-cat-list > li:last-child > a {
  padding-bottom: 0;
}

/* tt-Ggrid categories list item counter */
@media (min-width: 992px) {
  .ttgr-cat-list {
    counter-reset: ttgr-cat-list-counter;
  }
  .ttgr-cat-list .ttgr-cat-item::before {
    position: absolute;
    counter-increment: ttgr-cat-list-counter;
    content: "" counter(ttgr-cat-list-counter, decimal-leading-zero);
    top: 5px;
    left: -40px;
    line-height: 1;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    opacity: 0.4;
  }
}

/* tt-Ggrid categories list close (for mobile devises) */
.ttgr-cat-close {
  margin-bottom: 25px;
  font-size: calc(13px + 0.6vw);
}
body:not(.is-mobile) .ttgr-cat-close {
  display: none;
}

/* tt-Grid items
  ================= */
.tt-grid-items-wrap {
}

/* tt-Grid item */
.tt-grid-item {
  position: relative;
  float: left;
  width: 100%;
}
.ttgr-item-inner {
  position: relative;
}

/* tt-Grid height class ("ttgr-height"). Use it on an element inside "ttgr-item-inner".
  ======================================== */
.ttgr-height {
  position: relative;
  padding-bottom: 35%;
}
@media (max-width: 768px) {
  .ttgr-height {
    position: relative;
    padding-bottom: 60%;
  }
}

body.tt-boxed .tt-wrap .ttgr-height {
  padding-bottom: 60%;
}

/* if class "ttgr-layout-*" enabled */
.tt-grid[class*="ttgr-layout-"] .ttgr-height {
  padding-bottom: 60%;
}
.tt-grid[class*="ttgr-layout-"].ttgr-portrait:not(.ttgr-layout-creative-1):not(.ttgr-layout-creative-2)
  .ttgr-height {
  padding-bottom: 140%;
}

/* tt-Grid layouts 
  =================== */
/* layout-2 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-2 .tt-grid-item {
    width: 50%;
  }
}
.tt-grid.ttgr-layout-2 .ttgr-height {
  padding-bottom: 65%;
}

.tt-grid.ttgr-layout-2.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-2.ttgr-portrait-half
  .tt-grid-item:nth-child(4n + 3)
  .ttgr-height,
.tt-grid.ttgr-layout-2.ttgr-portrait-half
  .tt-grid-item:nth-child(4n + 4)
  .ttgr-height {
  padding-bottom: 140%;
}

/* layout-3 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-3 .tt-grid-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .tt-grid.ttgr-layout-3 .tt-grid-item {
    width: 33.33333%;
  }
}
.tt-grid.ttgr-layout-3 .ttgr-height {
  padding-bottom: 70%;
}

.tt-grid.ttgr-layout-3.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-3.ttgr-portrait-half
  .tt-grid-item:nth-child(6n + 4)
  .ttgr-height,
.tt-grid.ttgr-layout-3.ttgr-portrait-half
  .tt-grid-item:nth-child(6n + 5)
  .ttgr-height,
.tt-grid.ttgr-layout-3.ttgr-portrait-half
  .tt-grid-item:nth-child(6n + 6)
  .ttgr-height {
  padding-bottom: 140%;
}

/* layout-4 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tt-grid.ttgr-layout-4 .tt-grid-item {
    width: 50%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .tt-grid.ttgr-layout-4 .tt-grid-item {
    width: 33.33333%;
  }
}

@media (min-width: 1400px) {
  .tt-grid.ttgr-layout-4 .tt-grid-item {
    width: 25%;
  }
}
.tt-grid.ttgr-layout-4 .ttgr-height {
  padding-bottom: 60%;
}

.tt-grid.ttgr-layout-4.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-4.ttgr-portrait-half
  .tt-grid-item:nth-child(3n + 5)
  .ttgr-height,
.tt-grid.ttgr-layout-4.ttgr-portrait-half
  .tt-grid-item:nth-child(6n + 7)
  .ttgr-height,
.tt-grid.ttgr-layout-4.ttgr-portrait-half
  .tt-grid-item:nth-child(9n + 6)
  .ttgr-height {
  padding-bottom: 140%;
}

/* layout-1-2 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-1-2 .tt-grid-item {
    width: 50%;
  }
}
.tt-grid.ttgr-layout-1-2 .tt-grid-item:nth-child(3n + 1) {
  width: 100%;
}
.tt-grid.ttgr-layout-1-2 .ttgr-height {
  padding-bottom: 60%;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-1-2 .tt-grid-item:nth-child(3n + 1) .ttgr-height {
    padding-bottom: calc(60% - 20%);
  }
}

.tt-grid.ttgr-layout-1-2.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-1-2.ttgr-portrait-half .ttgr-height {
  padding-bottom: 120%;
}
.tt-grid.ttgr-layout-1-2.ttgr-portrait
  .tt-grid-item:nth-child(3n + 1)
  .ttgr-height {
  padding-bottom: 100%;
}

/* layout-2-1 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-2-1 .tt-grid-item {
    width: 50%;
  }
}
.tt-grid.ttgr-layout-2-1 .tt-grid-item:nth-child(3n + 0) {
  width: 100%;
}
.tt-grid.ttgr-layout-2-1 .ttgr-height {
  padding-bottom: 60%;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-2-1 .tt-grid-item:nth-child(3n + 0) .ttgr-height {
    padding-bottom: calc(60% - 20%);
  }
}

.tt-grid.ttgr-layout-2-1.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-2-1.ttgr-portrait-half .ttgr-height {
  padding-bottom: 120%;
}
.tt-grid.ttgr-layout-2-1.ttgr-portrait
  .tt-grid-item:nth-child(3n + 0)
  .ttgr-height {
  padding-bottom: 100%;
}

/* layout-2-3 */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .tt-grid.ttgr-layout-2-3 .tt-grid-item {
    width: 50%;
  }
  .tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(3n + 1) {
    width: 100%;
  }
  .tt-grid.ttgr-layout-2-3 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(3n + 1) .ttgr-height {
    padding-bottom: calc(60% - 20%) !important;
  }
}

@media (min-width: 1200px) {
  .tt-grid.ttgr-layout-2-3 .tt-grid-item {
    width: 33.33333%;
  }
  .tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(5n + 1),
  .tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(5n + 2) {
    width: 50%;
  }
}
.tt-grid.ttgr-layout-2-3 .ttgr-height {
  padding-bottom: 70%;
}
.tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(5n + 1) .ttgr-height,
.tt-grid.ttgr-layout-2-3 .tt-grid-item:nth-child(5n + 2) .ttgr-height {
  padding-bottom: 60%;
}

.tt-grid.ttgr-layout-2-3.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-2-3.ttgr-portrait
  .tt-grid-item:nth-child(5n + 1)
  .ttgr-height,
.tt-grid.ttgr-layout-2-3.ttgr-portrait
  .tt-grid-item:nth-child(5n + 2)
  .ttgr-height,
.tt-grid.ttgr-layout-2-3.ttgr-portrait-half .ttgr-height {
  padding-bottom: 120%;
}

/* layout-3-2 */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .tt-grid.ttgr-layout-3-2 .tt-grid-item {
    width: 50%;
  }
  .tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(3n + 0) {
    width: 100%;
  }
  .tt-grid.ttgr-layout-3-2 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(3n + 0) .ttgr-height {
    padding-bottom: calc(60% - 20%) !important;
  }
}
@media (min-width: 1200px) {
  .tt-grid.ttgr-layout-3-2 .tt-grid-item {
    width: 33.33333%;
  }
  .tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(5n + 4),
  .tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(5n + 5) {
    width: 50%;
  }
}
.tt-grid.ttgr-layout-3-2 .ttgr-height {
  padding-bottom: 70%;
}
.tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(5n + 4) .ttgr-height,
.tt-grid.ttgr-layout-3-2 .tt-grid-item:nth-child(5n + 5) .ttgr-height {
  padding-bottom: 60%;
}

.tt-grid.ttgr-layout-3-2.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-3-2.ttgr-portrait
  .tt-grid-item:nth-child(5n + 4)
  .ttgr-height,
.tt-grid.ttgr-layout-3-2.ttgr-portrait
  .tt-grid-item:nth-child(5n + 5)
  .ttgr-height,
.tt-grid.ttgr-layout-3-2.ttgr-portrait-half .ttgr-height {
  padding-bottom: 120%;
}

/* layout-3-4 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tt-grid.ttgr-layout-3-4 .tt-grid-item {
    width: 50%;
  }
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(3n + 1) {
    width: 100%;
  }
  .tt-grid.ttgr-layout-3-4 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(3n + 1) .ttgr-height {
    padding-bottom: calc(60% - 20%) !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .tt-grid.ttgr-layout-3-4 .tt-grid-item {
    width: 33.33333%;
  }
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(5n + 1),
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(5n + 2) {
    width: 50%;
  }
  .tt-grid.ttgr-layout-3-4 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(5n + 1) .ttgr-height,
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(5n + 2) .ttgr-height {
    padding-bottom: 60% !important;
  }
}

@media (min-width: 1400px) {
  .tt-grid.ttgr-layout-3-4 .tt-grid-item {
    width: 25%;
  }
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 1),
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 2),
  .tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 3) {
    width: 33.33333%;
  }
}
.tt-grid.ttgr-layout-3-4 .ttgr-height {
  padding-bottom: 70%;
}
.tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 1) .ttgr-height,
.tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 2) .ttgr-height,
.tt-grid.ttgr-layout-3-4 .tt-grid-item:nth-child(7n + 3) .ttgr-height {
  padding-bottom: 60%;
}

.tt-grid.ttgr-layout-3-4.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-3-4.ttgr-portrait
  .tt-grid-item:nth-child(7n + 1)
  .ttgr-height,
.tt-grid.ttgr-layout-3-4.ttgr-portrait
  .tt-grid-item:nth-child(7n + 2)
  .ttgr-height,
.tt-grid.ttgr-layout-3-4.ttgr-portrait
  .tt-grid-item:nth-child(7n + 3)
  .ttgr-height,
.tt-grid.ttgr-layout-3-4.ttgr-portrait-half .ttgr-height {
  padding-bottom: 140%;
}

/* layout-4-3 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tt-grid.ttgr-layout-4-3 .tt-grid-item {
    width: 50%;
  }
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(3n + 0) {
    width: 100%;
  }
  .tt-grid.ttgr-layout-4-3 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(3n + 0) .ttgr-height {
    padding-bottom: calc(60% - 20%) !important;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .tt-grid.ttgr-layout-4-3 .tt-grid-item {
    width: 33.33333%;
  }
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(5n + 4),
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(5n + 5) {
    width: 50%;
  }
  .tt-grid.ttgr-layout-4-3 .ttgr-height {
    padding-bottom: 70% !important;
  }
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(5n + 4) .ttgr-height,
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(5n + 5) .ttgr-height {
    padding-bottom: 60% !important;
  }
}

@media (min-width: 1400px) {
  .tt-grid.ttgr-layout-4-3 .tt-grid-item {
    width: 25%;
  }
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 5),
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 6),
  .tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 7) {
    width: 33.33333%;
  }
}
.tt-grid.ttgr-layout-4-3 .ttgr-height {
  padding-bottom: 70%;
}
.tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 5) .ttgr-height,
.tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 6) .ttgr-height,
.tt-grid.ttgr-layout-4-3 .tt-grid-item:nth-child(7n + 7) .ttgr-height {
  padding-bottom: 60%;
}

.tt-grid.ttgr-layout-4-3.ttgr-portrait .ttgr-height,
.tt-grid.ttgr-layout-4-3.ttgr-portrait
  .tt-grid-item:nth-child(7n + 5)
  .ttgr-height,
.tt-grid.ttgr-layout-4-3.ttgr-portrait
  .tt-grid-item:nth-child(7n + 6)
  .ttgr-height,
.tt-grid.ttgr-layout-4-3.ttgr-portrait
  .tt-grid-item:nth-child(7n + 7)
  .ttgr-height,
.tt-grid.ttgr-layout-4-3.ttgr-portrait-half .ttgr-height {
  padding-bottom: 140%;
}

/* layout-creative-1 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n + 1),
  .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n + 5) {
    width: 60%;
  }

  .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n + 2),
  .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n + 4) {
    width: 40%;
    padding-top: 12vw;
  }

  .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-child(3n + 3) {
    width: 100%;
    padding: 0 14vw;
  }
  .tt-grid.ttgr-layout-creative-1:not(.ttgr-portrait):not(.ttgr-not-cropped)
    .tt-grid-item:nth-child(3n + 3)
    .ttgr-height {
    padding-bottom: 55%;
  }
}

/* layout-creative-2 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-creative-2 .tt-grid-item:nth-of-type(4n + 1),
  .tt-grid.ttgr-layout-creative-2 .tt-grid-item:nth-of-type(4n + 4) {
    width: 58%;
  }

  .tt-grid.ttgr-layout-creative-2 .tt-grid-item:nth-of-type(4n + 2),
  .tt-grid.ttgr-layout-creative-2 .tt-grid-item:nth-of-type(4n + 3) {
    width: 42%;
    padding-top: 9.8vw;
  }

  .tt-grid.ttgr-layout-creative-2 .tt-grid-item:last-child:nth-child(odd) {
    width: 100%;
    padding: 0 14vw;
  }
  .tt-grid.ttgr-layout-creative-2:not(.ttgr-portrait):not(.ttgr-not-cropped)
    .tt-grid-item:last-child:nth-child(odd)
    .ttgr-height {
    padding-bottom: 55%;
  }
}

/* tt-grid items shifted 
  ========================= */
/* Without layout class (one column) */
@media (min-width: 768px) {
  .tt-grid.ttgr-shifted:not([class*="ttgr-layout-"])
    .tt-grid-item:nth-child(even)
    .ttgr-item-inner {
    margin-left: 20vw;
  }
  .tt-grid.ttgr-shifted:not([class*="ttgr-layout-"])
    .tt-grid-item:nth-child(odd)
    .ttgr-item-inner {
    margin-right: 20vw;
  }
}

/* With layout 2 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-2.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner {
    margin-top: 10vw;
  }
}

/* With layout 3 */
@media (min-width: 768px) {
  .tt-grid.ttgr-layout-3.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner {
    margin-top: 15vw;
  }
}
@media (min-width: 1200px) {
  .tt-grid.ttgr-layout-3.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner {
    margin-top: 10vw;
  }
}

/* With layout 4 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tt-grid.ttgr-layout-4.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner {
    margin-top: 15vw;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1399px) {
  .tt-grid.ttgr-layout-4.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner {
    margin-top: 10vw;
  }
}
@media (min-width: 1400px) {
  .tt-grid.ttgr-layout-4.ttgr-shifted
    .tt-grid-item:nth-child(2)
    .ttgr-item-inner,
  .tt-grid.ttgr-layout-4.ttgr-shifted
    .tt-grid-item:nth-child(4)
    .ttgr-item-inner {
    margin-top: 8vw;
  }
}

/* tt-grid item gaps 
  ===================== */
/* Gap 1 */
.tt-grid.ttgr-gap-1,
.tt-grid.ttgr-gap-1 .tt-grid-top {
  margin-left: 0.2vw;
  margin-right: 0.2vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-1 {
  margin-left: -0.2vw;
  margin-right: -0.2vw;
}
body:not(.tt-boxed) .tt-section-inner.tt-wrap .tt-grid.ttgr-gap-1 .tt-grid-top,
.tt-section-inner:not(.tt-wrap) .tt-grid.ttgr-gap-1 .tt-grid-top {
  padding-left: 3vw;
  padding-right: 3vw;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-gap-1 .ttgr-item-inner {
    margin: 0 0.2vw 0.4vw 0.2vw;
  }
}
@media (max-width: 768px) {
  .tt-grid.ttgr-gap-1 .ttgr-item-inner {
    margin: 0 1vw 1vw 1vw;
  }
}

/* Gap 2 */
.tt-grid.ttgr-gap-2,
.tt-grid.ttgr-gap-2 .tt-grid-top {
  margin-left: 1vw;
  margin-right: 1vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-2 {
  margin-left: -1vw;
  margin-right: -1vw;
}
body:not(.tt-boxed) .tt-section-inner.tt-wrap .tt-grid.ttgr-gap-2 .tt-grid-top,
.tt-section-inner:not(.tt-wrap) .tt-grid.ttgr-gap-2 .tt-grid-top {
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}
.tt-grid.ttgr-gap-2 .ttgr-item-inner {
  margin: 0 1vw 2vw 1vw;
}

/* Gap 3 */
.tt-grid.ttgr-gap-3,
.tt-grid.ttgr-gap-3 .tt-grid-top {
  margin-left: 2vw;
  margin-right: 2vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-3 {
  margin-left: -2vw;
  margin-right: -2vw;
}
.tt-grid.ttgr-gap-3 .ttgr-item-inner {
  margin: 0 2vw 4vw 2vw;
}

/* Gap 4 */
.tt-grid.ttgr-gap-4,
.tt-grid.ttgr-gap-4 .tt-grid-top {
  margin-left: 3vw;
  margin-right: 3vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-4 {
  margin-left: -3vw;
  margin-right: -3vw;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-gap-4 .ttgr-item-inner {
    margin: 0 3vw 6vw 3vw;
  }
}
@media (max-width: 768px) {
  .tt-grid.ttgr-gap-4 .ttgr-item-inner {
    margin: 0 1.5vw 5vw 1.5vw;
  }
}

/* Gap 5 */
.tt-grid.ttgr-gap-5,
.tt-grid.ttgr-gap-5 .tt-grid-top {
  margin-left: 4vw;
  margin-right: 4vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-5 {
  margin-left: -4vw;
  margin-right: -4vw;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-gap-5 .ttgr-item-inner {
    margin: 0 4vw 8vw 4vw;
  }
}
@media (max-width: 768px) {
  .tt-grid.ttgr-gap-5 .ttgr-item-inner {
    margin-bottom: 5vw;
  }
}

/* Gap 6 */
.tt-grid.ttgr-gap-6,
.tt-grid.ttgr-gap-6 .tt-grid-top {
  margin-left: 5vw;
  margin-right: 5vw;
}
body.tt-boxed .tt-wrap .tt-grid.ttgr-gap-6 {
  margin-left: -5vw;
  margin-right: -5vw;
}
@media (min-width: 768px) {
  .tt-grid.ttgr-gap-6 .ttgr-item-inner {
    margin: 0 5vw 10vw 5vw;
  }
}
@media (max-width: 768px) {
  .tt-grid.ttgr-gap-6 .ttgr-item-inner {
    margin-bottom: 6vw;
  }
}

/* tt-grid item image not cropped 
  ================================== */
.tt-grid.ttgr-not-cropped:not([class*="ttgr-layout-"])
  .ttgr-height:not(.tt-gallery-video-wrap):not(.pgi-video-wrap),
.tt-grid.ttgr-layout-2.ttgr-not-cropped
  .ttgr-height:not(.tt-gallery-video-wrap):not(.pgi-video-wrap),
.tt-grid.ttgr-layout-3.ttgr-not-cropped
  .ttgr-height:not(.tt-gallery-video-wrap):not(.pgi-video-wrap),
.tt-grid.ttgr-layout-4.ttgr-not-cropped
  .ttgr-height:not(.tt-gallery-video-wrap):not(.pgi-video-wrap) {
  padding-bottom: 0;
  line-height: 0;
}
.tt-grid.ttgr-not-cropped:not([class*="ttgr-layout-"]) .ttgr-height img,
.tt-grid.ttgr-layout-2.ttgr-not-cropped .ttgr-height img,
.tt-grid.ttgr-layout-3.ttgr-not-cropped .ttgr-height img,
.tt-grid.ttgr-layout-4.ttgr-not-cropped .ttgr-height img {
  position: relative;
  top: unset;
  left: unset;
  width: auto;
  height: auto;
  object-fit: unset;
  object-position: unset;
}
.tt-grid.ttgr-not-cropped:not([class*="ttgr-layout-"])
  .ttgr-height.tt-gallery-video-wrap,
.tt-grid.ttgr-not-cropped:not([class*="ttgr-layout-"])
  .ttgr-height.pgi-video-wrap {
  padding-bottom: 60%;
}

/* ------------------------------------------------------------- *
   * Portfolio grid
  /* ------------------------------------------------------------- */

#portfolio-grid {
  position: relative;
}

/* Portfolio grid item */
.portfolio-grid-item {
  position: relative;
}

/* Portfolio grid item image 
  ============================= */
.pgi-image-wrap {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  line-height: 1;
}
.pgi-image-holder {
}
.pgi-image-inner {
}
.pgi-image {
  position: relative;
}
.pgi-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* video */
.pgi-video-wrap {
  position: relative;
}
video.pgi-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Portfolio grid item caption 
  =============================== */
.pgi-caption {
  width: 100%;
  max-width: 900px;
  padding: 20px 3%;
}
.pgi-caption-inner {
}

/* Portfolio grid item title */
.pgi-title {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: calc(19px + 1.5vw);
  font-weight: bold;
  font-weight: 600;
  color: #fff;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .pgi-title {
    font-size: calc(19px + 1.5vw);
  }
}

/* Portfolio grid item title hover (no effect if "pgi-cap-inside" enabled!) */
#portfolio-grid:not(.pgi-cap-inside) .pgi-title a {
  display: inline;
}
#portfolio-grid:not(.pgi-cap-inside) .pgi-title a {
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
#portfolio-grid:not(.pgi-cap-inside) .pgi-title a:hover {
  background-size: 100% 96%;
}

/* Portfolio grid item categories */
.pgi-categories-wrap {
  position: relative;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}
.pgi-category {
  position: relative;
  display: inline-block;
  font-size: calc(13px + 0.1vw);
  color: #fff;
  opacity: 0.5;
}
.pgi-category ~ .pgi-category {
  margin-left: -4px;
}
.pgi-category ~ .pgi-category:not(:empty)::before {
  content: ", ";
}
.pgi-category ~ .pgi-category ~ .pgi-category ~ .pgi-category {
  visibility: hidden;
  width: 0;
}
.pgi-category ~ .pgi-category ~ .pgi-category ~ .pgi-category ~ .pgi-category {
  display: none;
}
.pgi-category + .pgi-category + .pgi-category + .pgi-category::before {
  content: "...";
  margin-left: 5px;
  visibility: visible;
}

/* Portfolio grid item caption sizes 
  ===================================== */
@media (min-width: 768px) {
  .ttgr-layout-2 .pgi-title,
  .ttgr-layout-1-2 .pgi-title,
  .ttgr-layout-2-1 .pgi-title,
  .ttgr-layout-creative-1 .pgi-title,
  .ttgr-layout-creative-2 .pgi-title {
    font-size: calc(19px + 0.4vw);
  }

  .ttgr-layout-3 .pgi-title,
  .ttgr-layout-2-3 .pgi-title,
  .ttgr-layout-3-2 .pgi-title {
    font-size: calc(19px + 0.3vw);
  }

  .ttgr-layout-4 .pgi-title,
  .ttgr-layout-3-4 .pgi-title,
  .ttgr-layout-4-3 .pgi-title {
    font-size: calc(19px + 0.2vw);
  }

  body.tt-boxed .tt-wrap .ttgr-layout-2 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-1-2 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-2-1 .pgi-title {
    font-size: calc(19px + 0.3vw);
  }

  body.tt-boxed .tt-wrap .ttgr-layout-3 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-2-3 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-3-2 .pgi-title {
    font-size: calc(19px + 0.3vw);
  }

  body.tt-boxed .tt-wrap .ttgr-layout-4 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-3-4 .pgi-title,
  body.tt-boxed .tt-wrap .ttgr-layout-4-3 .pgi-title {
    font-size: calc(19px + 0.2vw);
  }
}

/* Portfolio grid item caption positions 
  ========================================= */
/* Position inside */
#portfolio-grid.pgi-cap-inside .pgi-caption {
  position: absolute;
  left: 0;
  bottom: 8%;
  padding: 0 8%;
  z-index: 2;
}
#portfolio-grid.pgi-cap-inside .pgi-category {
  opacity: 1;
}

/* Position center */
#portfolio-grid.pgi-cap-center .pgi-caption {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
#portfolio-grid.pgi-cap-center .pgi-caption-inner {
  text-align: center;
}
#portfolio-grid.pgi-cap-inside.pgi-cap-center .pgi-caption {
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%) !important;
}

/* Portfolio grid item caption styles 
  ====================================== */
/* If light image used (effect only if "pgi-cap-inside" enabled) */
@media (min-width: 1025px) {
  #portfolio-grid.pgi-cap-inside .pgi-image-is-light .pgi-title {
    color: #111;
  }
  #portfolio-grid.pgi-cap-inside .pgi-image-is-light .pgi-category {
    color: #111;
  }
  #portfolio-grid.pgi-cap-inside
    .pgi-image-is-light
    .pgi-image-holder[class*="cover-opacity-"]::before {
    display: none !important;
  }
}

/* Portfolio grid item hover 
  ============================= */

/* Clip path (effect only with gaps) */
@media (min-width: 768px) {
  #portfolio-grid.pgi-hover
    [class*="ttgr-gap-"]:not(.ttgr-gap-1)
    .pgi-image-holder {
    clip-path: inset(0 0 0 0);
    overflow: hidden;
    transition: clip-path 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  #portfolio-grid.pgi-hover
    [class*="ttgr-gap-"]:not(.ttgr-gap-1)
    .pgi-image-wrap:hover
    .pgi-image-holder {
    clip-path: inset(3% 3% 3% 3%);
  }

  /* Image zoom */
  #portfolio-grid.pgi-hover .pgi-image {
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #portfolio-grid.pgi-hover .pgi-image-wrap:hover .pgi-image {
    transform: scale(1.05);
  }
}

/* Caption hover */
body:not(.is-mobile) #portfolio-grid.pgi-cap-inside.pgi-cap-hover .pgi-title {
  transform: translate3d(0, 15px, 0);
  transition: all 0.25s ease-out;
  transition-delay: 0.1s;
}
body:not(.is-mobile)
  #portfolio-grid.pgi-cap-inside.pgi-cap-hover
  .portfolio-grid-item:hover
  .pgi-title {
  transform: translate3d(0, 0, 0);
}

body:not(.is-mobile)
  #portfolio-grid.pgi-cap-inside.pgi-cap-hover
  .pgi-categories-wrap {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 20px, 0);
  transition: all 0.25s ease-out;
  transition-delay: 0.1s;
}
body:not(.is-mobile)
  #portfolio-grid.pgi-cap-inside.pgi-cap-hover
  .portfolio-grid-item:hover
  .pgi-categories-wrap {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

/* ------------------------------------------------------------- *
   * Portfolio List
  /* ------------------------------------------------------------- */

.portfolio-list {
  position: relative;
}

/* Portfolio list item 
  ======================= */
.portfolio-list-item {
  position: relative;
  margin-bottom: 80px;
}
.portfolio-list-item:first-child {
}
.portfolio-list-item:last-child {
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .portfolio-list-item {
    margin-bottom: 8%;
  }
}
@media (min-width: 1025px) {
  body.tt-boxed .tt-section-inner:not(.tt-wrap) .portfolio-list-item {
    margin-left: 17%;
    margin-right: 17%;
  }
}
@media (max-width: 1024px) {
  body.tt-boxed .tt-section-inner:not(.tt-wrap) .portfolio-list-item {
    margin-left: 5%;
    margin-right: 5%;
  }
}

/* Portfolio list item inner */
.pli-inner {
  position: relative;
}
@media (min-width: 768px) {
  .pli-inner {
    display: flex;
  }
}

/* Portfolio list item image 
  ============================= */
.pli-image-col {
  line-height: 0;
}

.pli-image-link {
  display: block;
  overflow: hidden;
}
.pli-image-holder {
}
.pli-image {
}
@media (max-width: 767px) {
  /* remove image cover */
  .pli-image[class*="cover-opacity-"]::before {
    display: none !important;
  }
}
.pli-image img {
}

.pli-video-wrap {
}
.pli-video {
  width: 100%;
}

@media (min-width: 768px) {
  .pli-image-col {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    z-index: 1;
  }

  /* Image hover */
  .pli-hover .pli-image-link {
    transition: all 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
    clip-path: inset(0 0 0 0);
  }
  .pli-hover .pli-image-col:hover .pli-image-link {
    clip-path: inset(3% 3% 3% 3%);
  }

  .pli-hover .pli-image .pli-image-hover-zoom {
    transition: all 1s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .pli-hover .pli-image-col:hover .pli-image .pli-image-hover-zoom {
    transform: scale(1.05);
  }
}

/* Portfolio list item image cropped
  ===================================== */
.portfolio-list.pli-cropped .pli-image img,
.portfolio-list.pli-cropped .pli-video {
  width: 100%;
  height: 33vw;
  object-fit: cover;
  object-position: 50% 50%;
}
.portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-image img,
.portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-video {
  height: 30vw;
}

@media (max-width: 1024px) {
  .portfolio-list.pli-cropped .pli-image img,
  .portfolio-list.pli-cropped .pli-video,
  .portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-image img,
  .portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-video {
    height: 46vw;
  }
}
@media (max-width: 767px) {
  .portfolio-list.pli-cropped .pli-image img,
  .portfolio-list.pli-cropped .pli-video,
  .portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-image img,
  .portfolio-list.pli-cropped:not(.pli-info-overlay) .pli-video {
    height: 60vw;
  }
}

/* Portfolio list item info 
  ============================ */
.pli-info-col {
  position: relative;
}
@media (min-width: 768px) {
  .pli-info-col {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media (max-width: 767px) {
  .pli-info-col {
    margin-top: 20px;
  }
}

.pli-info {
  position: relative;
  line-height: 1;
}
@media (min-width: 768px) {
  .pli-info {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding-left: 15%;
    transform: translate3d(0, -50%, 0);
  }
}

.pli-title {
  margin: 0;
  font-size: calc(16px + 2vw);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.3;
}
.pli-title .split-line-wrap {
  line-height: 1.3;
}
.pli-title a {
  display: inline;
  color: #fff;
}
.portfolio-list:not(.pli-info-overlay) .pli-title a {
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 2px),
    currentColor 2px
  );
  background-image: linear-gradient(
    transparent calc(100% - 2px),
    currentColor 2px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 2px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.portfolio-list:not(.pli-info-overlay) .pli-title a:hover {
  background-size: 100% 96%;
}

.pli-categories-wrap {
  position: relative;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .pli-categories-wrap {
    margin-top: 15px;
  }
}
.pli-category {
  position: relative;
  display: inline-block;
  font-size: calc(13px + 0.1vw);
  color: #fff;
  line-height: 1.6;
}
.portfolio-list:not(.pli-info-overlay) .pli-category {
  opacity: 0.5;
}
.pli-category ~ .pli-category {
  margin-left: -4px;
}
.pli-category ~ .pli-category:not(:empty)::before {
  content: ", ";
}
.pli-category ~ .pli-category ~ .pli-category ~ .pli-category {
  visibility: hidden;
  width: 0;
}
.pli-category ~ .pli-category ~ .pli-category ~ .pli-category ~ .pli-category {
  display: none;
}
.pli-category + .pli-category + .pli-category + .pli-category::before {
  content: "...";
  margin-left: 5px;
  visibility: visible;
}

/* Portfolio list item counter
  =============================== */
.portfolio-list {
  counter-reset: pli-counter;
}
.pli-counter {
  position: absolute;
  display: inline-block;
  top: -60px;
  left: 15%;
  color: #fff;
  opacity: 0.4;
}
.pli-counter::before {
  counter-increment: pli-counter;
  content: "" counter(pli-counter, decimal-leading-zero);
  line-height: 1;
  font-size: calc(17px + 0.1vw);
  font-weight: normal;
  z-index: 99999;
}
@media (min-width: 768px) {
  .portfolio-list.pl-alter .portfolio-list-item:nth-child(even) .pli-counter {
    right: 15%;
    left: auto;
  }
  .portfolio-list.pli-info-overlay .pli-counter {
    left: 53%;
  }
  .portfolio-list.pl-alter.pli-info-overlay
    .portfolio-list-item:nth-child(even)
    .pli-counter {
    right: 53%;
    left: auto;
  }
  .portfolio-list.pl-alter
    .portfolio-list-item:nth-child(even)
    .pli-counter::before,
  .portfolio-list.pl-alter.pli-info-overlay
    .portfolio-list-item:nth-child(even)
    .pli-counter::before {
    left: auto;
    right: 0;
  }
}
@media (max-width: 767px) {
  .pli-counter {
    display: none;
  }
}

/* Portfolio list alternative layout 
  ===================================== */
@media (min-width: 768px) {
  .portfolio-list.pl-alter .portfolio-list-item {
    margin-bottom: 10%;
  }
  .portfolio-list.pl-alter .portfolio-list-item:nth-child(even) .pli-inner {
    flex-direction: row-reverse;
  }

  .portfolio-list.pl-alter .portfolio-list-item:nth-child(even) .pli-info {
    right: auto;
    left: 0;
    padding-left: 0;
    padding-right: 15%;
    text-align: right;
  }
}

/* Portfolio list compact layout 
  ================================= */
@media (min-width: 421px) {
  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-inner {
    display: flex;
  }
  .portfolio-list:not(.pli-info-overlay).pl-compact .portfolio-list-item {
    margin-bottom: 2%;
    padding-bottom: 2%;
    border-bottom: 1px solid #252525;
  }
  .portfolio-list:not(.pli-info-overlay).pl-compact
    .portfolio-list-item:last-child {
    margin-bottom: 0;
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-image-col {
    display: flex;
    align-items: center;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .portfolio-list:not(.pli-info-overlay).pl-compact
    .pli-info-col.pli-info-outer {
    display: flex;
    align-items: center;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-info {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    padding-left: 10%;
    transform: none;
  }
  .portfolio-list:not(.pli-info-overlay).pl-alter.pl-compact
    .portfolio-list-item:nth-child(even)
    .pli-info {
    padding-right: 10%;
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-image
    img,
  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-video {
    height: 100%;
    min-height: 10vw;
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-counter {
    position: absolute;
    display: inline-block;
    top: 35%;
    left: auto;
    right: 0;
    line-height: 1;
    opacity: 0.1;
    transform: translate3d(0, -50%, 0);
  }
  .portfolio-list:not(.pli-info-overlay).pl-alter.pl-compact
    .portfolio-list-item:nth-child(even)
    .pli-counter {
    right: auto;
    left: 0;
  }
  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-counter::before {
    font-size: calc(42px + 6vw);
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact .pli-categories-wrap {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .portfolio-list:not(.pli-info-overlay).pl-compact .portfolio-list-item {
    margin-bottom: 3%;
    padding-bottom: 3%;
  }

  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-image
    img,
  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-video {
    height: 20vw;
  }
}
@media (max-width: 420px) {
  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-image
    img,
  .portfolio-list:not(.pli-info-overlay).pl-compact.pli-cropped:not(.pli-info-overlay)
    .pli-video {
    height: 60vw;
  }
}

/* Portfolio list item info overlay 
  ==================================== */
.pli-info-inner {
  display: none;
}

@media (min-width: 768px) {
  .portfolio-list.pli-info-overlay .pli-image-col {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .portfolio-list.pli-info-overlay .pli-info-col {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  .portfolio-list.pli-info-overlay .pli-info {
    left: -35%;
    width: 135%; /* 100 + overlay % */
    padding-left: 0;
  }
  .portfolio-list.pl-alter.pli-info-overlay
    .portfolio-list-item:nth-child(even)
    .pli-info {
    right: -35%;
    left: auto;
    padding-right: 0;
  }

  /* Portfolio list item info inner */
  .portfolio-list.pli-info-overlay .pli-info-inner {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .portfolio-list.pl-alter.pli-info-overlay
    .portfolio-list-item:nth-child(even)
    .pli-info-inner {
    left: 0;
    right: auto;
  }

  /* Portfolio list item info outer */
  .portfolio-list.pli-info-overlay .pli-info-outer {
    z-index: -1;
  }
}

/* Portfolio list info size
  ============================ */
@media (min-width: 768px) {
  /* size sm */
  .portfolio-list.pli-info-sm .pli-title {
    font-size: calc(21px + 0.8vw);
  }

  /* size lg */
  .portfolio-list.pli-info-lg .pli-title {
    font-size: calc(16px + 3vw);
  }

  /* size xlg */
  .portfolio-list.pli-info-xlg .pli-title {
    font-size: calc(16px + 3.5vw);
  }

  /* size xxlg */
  .portfolio-list.pli-info-xxlg .pli-title {
    font-size: calc(16px + 4.5vw);
  }
}

/* Portfolio list item info styles 
  =================================== */
/* Title stroke effect */
@media (min-width: 768px) {
  .portfolio-list.pli-title-stroke .pli-title a {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: transparent;
  }
  .portfolio-list.pli-title-stroke .pli-title {
    font-weight: bold;
    font-weight: 700;
  }

  /* If Firefox */
  body.is-firefox .portfolio-list.pli-title-stroke .pli-title a {
    -webkit-text-stroke-width: 2px;
  }
}

/* If light image used (effect only if "pgi-cap-inside" enabled) */
@media (min-width: 768px) {
  .pli-info-overlay .portfolio-list-item.pli-image-is-light .pli-title {
    color: #111;
  }
  .pli-info-overlay .portfolio-list-item.pli-image-is-light .pli-category {
    color: #111;
    font-weight: 500;
  }
  .pli-info-overlay
    .portfolio-list-item.pli-image-is-light
    .pli-image[class*="cover-opacity-"]::before {
    display: none !important;
  }
}

/* ------------------------------------------------------------- *
   * Portfolio interactive
  /* ------------------------------------------------------------- */

.portfolio-interactive {
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
}
@media (max-width: 1024px) {
  .portfolio-interactive {
    padding-left: 5%;
    padding-right: 5%;
  }
}
body.tt-boxed .tt-wrap .portfolio-interactive {
  padding-left: 3%;
  padding-right: 3%;
}

/* Portfolio interactive item */
.portfolio-interactive-item {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (max-width: 1024px) {
  .portfolio-interactive-item {
    padding: 8% 3% 5% 3%;
    margin-bottom: 20px;
  }
}

/* Portfolio interactive item counter */
.portfolio-interactive {
  counter-reset: pi-item-counter;
}
.portfolio-interactive-item::before {
  position: absolute;
  counter-increment: pi-item-counter;
  content: "" counter(pi-item-counter, decimal-leading-zero);
  top: 10%;
  left: -40px;
  line-height: 1;
  font-size: calc(14px + 0.2vw);
  font-weight: 300;
  color: #fff;
  opacity: 0.3;
}
@media (max-width: 1024px) {
  .portfolio-interactive-item::before {
    top: 10%;
    left: 3%;
    opacity: 0.5;
  }
}

/* Portfolio interactive item category */
.pi-item-category-wrap {
  position: absolute;
  top: 8%;
  right: 0;
  line-height: 1;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 1024px) {
  .pi-item-category-wrap {
    right: 3%;
  }
}
.pi-item-category {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: calc(13px + 0.1vw);
  font-weight: normal;
  color: #b3b3b3;
  line-height: 1.4;
  transition: transform 0.6s cubic-bezier(0.51, 0.57, 0.17, 1);
}
@media (min-width: 1025px) {
  body:not(.is-mobile) .pi-item-category {
    font-size: calc(13px + 0.1vw);
    transform: translate3d(0, 100%, 0);
  }
  body:not(.is-mobile)
    .portfolio-interactive-item.pi-item-hover
    .pi-item-category {
    transform: translate3d(0, 0, 0);
    transition-delay: 0.2s;
  }
}
.pi-item-category ~ .pi-item-category {
  margin-left: -4px;
}
.pi-item-category ~ .pi-item-category:not(:empty)::before {
  content: ", ";
}
.pi-item-category ~ .pi-item-category ~ .pi-item-category ~ .pi-item-category {
  visibility: hidden;
  width: 22px;
}
.pi-item-category
  ~ .pi-item-category
  ~ .pi-item-category
  ~ .pi-item-category
  ~ .pi-item-category {
  display: none;
}
.pi-item-category
  + .pi-item-category
  + .pi-item-category
  + .pi-item-category::before {
  content: "...";
  margin-left: 5px;
  visibility: visible;
}

/* Portfolio interactive item link */
.pi-item-title-link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
}

/* Portfolio interactive item title */
.pi-item-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  font-size: calc(14px + 5vw);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.5;
  opacity: 0.5;
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s cubic-bezier(0.51, 0.57, 0.17, 1);

  /* Ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
}
.pi-item-hover-title {
  position: absolute;
  display: block;
  left: 0;
  margin: 0;
  padding: 0;
  font-size: calc(14px + 5vw);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.5;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.6s cubic-bezier(0.51, 0.57, 0.17, 1);
}
@media (min-width: 1025px) {
  .pi-item-title,
  .pi-item-hover-title {
    white-space: nowrap;
  }

  .pi-item-hover-title {
    width: fit-content;
    display: flex;
  }

  .pi-item-title-link:hover .pi-item-title {
    transform: translate3d(0, -100%, 0);
    transition-delay: 0.1s;
  }
  .pi-item-title-link:hover .pi-item-hover-title {
    transform: translate3d(0, 0, 0);
    transition-delay: 0.1s;
  }

  .pi-item-hover-title span {
    align-items: center;
    padding-right: 5vw;
    padding-top: 20px;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    /* animation-duration is handled by js. */
  }
  .pi-item-title-link:hover .pi-item-hover-title span {
    animation-play-state: running;
    /* animation-duration is handled by js. */
  }
  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
@media (max-width: 1024px) {
  .pi-item-title {
    opacity: 0.9;
    font-size: calc(14px + 2vw);
    line-height: 1.4;
  }
  .pi-item-hover-title {
    display: none;
  }
}

/* Portfolio interactive item title separator (dot) */
.pi-item-hover-title span::after {
  position: absolute;
  display: block;
  content: "";
  right: 1.9vw;
  top: 56%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: #fff;
  border-radius: 100px;
  transform: translate3d(0, -50%, 0);
}

/* Portfolio interactive image */
.pi-item-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
@media (min-width: 1025px) {
  .portfolio-interactive-item .pi-item-image {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pi-item-image {
    overflow: hidden;
  }
  .pi-item-image::before {
    position: absolute;
    display: block;
    content: "";
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background-color: #040404;
    opacity: 0.7;
  }
}
.pi-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#magic-cursor.portfolio-interactive-hover-on {
  z-index: -1;
}
#magic-cursor.portfolio-interactive-hover-on #ball {
  overflow: hidden;
  border: none !important;
}

/* Video */
video.pi-item-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Portfolio interactive styles 
  ================================ */
/* Style stroke (no effect on small screens) */
@media (min-width: 1025px) {
  .portfolio-interactive.pi-stroke .pi-item-title {
    font-weight: bold;
    font-weight: 500;
    font-size: 140px;
    padding-right: 50px;
    font-family: "SWEETREVENGE", sans-serif;
    opacity: 1;
    -webkit-text-stroke-width: 3.5px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: transparent;
  }
  body.is-firefox .portfolio-interactive.pi-stroke .pi-item-title {
    -webkit-text-stroke-width: 2px;
  }
}

/* Style compact */
@media (min-width: 1025px) {
  .portfolio-interactive.pi-compact .portfolio-interactive-item {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-right: 15%;
  }

  .portfolio-interactive.pi-compact .pi-item-title {
    font-size: calc(14px + 3.2vw);
    opacity: 0.7;
  }
  .portfolio-interactive.pi-compact .pi-item-hover-title {
    font-size: calc(14px + 3.2vw);
  }

  .portfolio-interactive.pi-compact .portfolio-interactive-item::before {
    top: 17%;
    left: auto;
    right: 0;
    font-size: calc(14px + 4vw);
    font-weight: normal;
    font-style: italic;
    color: #fff;
    opacity: 0.2;
  }

  .portfolio-interactive.pi-compact .pi-item-category-wrap {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }
}

/* Style with porders */
@media (min-width: 1025px) {
  .portfolio-interactive.pi-borders .portfolio-interactive-item {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 3px solid;
    border-image: linear-gradient(to right, #eace00 0%, #8bd4ef 100%);
    border-image-slice: 1;
  }
  .portfolio-interactive.pi-borders .portfolio-interactive-item::before {
    top: 8%;
    left: 0;
    display: none;
  }

  .portfolio-interactive.pi-compact.pi-borders .portfolio-interactive-item {
    padding-top: 35px;
    padding-bottom: 45px;
  }
  .portfolio-interactive.pi-compact.pi-borders
    .portfolio-interactive-item::before {
    left: auto;
    top: 25%;
  }
  .portfolio-interactive.pi-compact.pi-borders .pi-item-category-wrap {
    bottom: 10%;
  }
}

/* ------------------------------------------------------------- *
   * Portfolio slider (full screen)
  /* ------------------------------------------------------------- */

.tt-portfolio-slider {
  position: relative;
  width: 100%;
  background-color: #0a0a0a;
}

.tt-portfolio-slider .swiper-container {
  height: 100vh;
}

/* Portfolio slider slide */
.tt-portfolio-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  background-color: #000;
}

/* Portfolio slider item 
  ========================= */
.tt-portfolio-slider-item {
  position: relative;
  height: 100%;
}

img.tt-psi-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Slide opasity cover (for prev/next slides) */
.tt-portfolio-slider-item::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background-color: #000;
  transition: 0.5s ease-in-out;
}
.swiper-slide-prev .tt-portfolio-slider-item::after,
.swiper-slide-next .tt-portfolio-slider-item::after {
  opacity: 0.7;
  visibility: visible;
}

/* Portfolio slider caption 
  ============================ */
.tt-portfolio-slider-caption {
  position: absolute;
  top: 55%;
  left: 0;
  width: auto;
  /*max-width: 1000px;*/
  margin: 0px 2% 0 12%;
  color: #fff;
  z-index: 998;
  pointer-events: none;
  transform: translate3d(0, -50%, 0);
}
@media (max-width: 767px) {
  .tt-portfolio-slider-caption {
    margin-left: 10%;
  }
}
.tt-ps-caption-inner {
}

.tt-portfolio-slider-caption a {
  pointer-events: initial;
}

/* Portfolio slider caption title */
.tt-ps-caption-title {
  margin: 0;
  font-size: clamp(40px, 5.2vw, 100px);
  font-weight: bold;
  font-weight: 600;
  line-height: 1.2;
}
.tt-ps-caption-title a {
  display: inline;
  color: #fff;
}

/* Portfolio slider caption title hover if the magic cursor is not enabled */
body:not(.tt-magic-cursor) .tt-ps-caption-title a {
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 3px),
    currentColor 3px
  );
  background-image: linear-gradient(
    transparent calc(100% - 3px),
    currentColor 3px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 3px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
body:not(.tt-magic-cursor) .tt-ps-caption-title a:hover {
  background-size: 100% 96%;
  color: currentColor;
}

/* Portfolio slider caption category */
.tt-ps-caption-category {
  margin: 30px 0 0 0;
  font-size: calc(13px + 0.1vw);
  font-weight: 500;
}
@media (max-width: 1024px) {
  .tt-ps-caption-category {
    margin: 30px 0 0 0;
  }
}

/* Portfolio slider caption position 
  ===================================== */
/* Position center */
.tt-portfolio-slider-caption.psc-center {
  top: 50%;
  left: 50%;
  width: 90%;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  transform: translate(-50%, -50%);
}

/* Portfolio slider caption styles 
  =================================== */
/* Style stroke */
.tt-portfolio-slider-caption.psc-stroke .tt-ps-caption-title {
  font-weight: bold;
  font-weight: 800;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 1200px) {
  .tt-portfolio-slider-caption.psc-stroke .tt-ps-caption-title {
    -webkit-text-stroke-width: 1px;
  }
}
@media (max-width: 991px) {
  .tt-portfolio-slider-caption.psc-stroke .tt-ps-caption-title {
    -webkit-text-stroke-width: 1px;
  }
}

/* Portfolio slider navigation 
  =============================== */
.tt-portfolio-slider-navigation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4%;
  left: 50%;
  width: 300px;
  height: 60px;
  z-index: 1;
  transform: translate3d(-50%, 0, 0);
}
@media (max-width: 767px) {
  .tt-portfolio-slider-navigation {
    width: 90%;
  }
}

.tt-ps-nav-prev,
.tt-ps-nav-next {
  position: absolute;
  top: 0;
  z-index: 20;
}
.tt-ps-nav-prev {
  left: 0;
}
.tt-ps-nav-next {
  right: 0;
}

.tt-ps-nav-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  outline: none;
}
body:not(.tt-magic-cursor) .tt-ps-nav-arrow:hover {
  opacity: 0.5;
}
.tt-ps-nav-arrow-prev::after,
.tt-ps-nav-arrow-next::after {
  line-height: 0;

  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.tt-ps-nav-arrow-prev::after {
  content: "\f053";
}
.tt-ps-nav-arrow-next::after {
  content: "\f054";
}

.tt-ps-nav-arrow.tt-ps-nav-arrow-disabled {
  /* opacity: .5; */
  cursor: auto;
  pointer-events: none;
}

.tt-portfolio-slider .swiper-button-lock {
  display: none;
}

/* Portfolio slider pagination 
  =============================== */
.tt-ps-nav-pagination {
}

.tt-portfolio-slider .swiper-pagination-lock {
  display: none;
}

/* Portfolio slider pagination bullets */
.tt-ps-nav-pagination-bullets {
  font-size: 0;
}
.tt-ps-nav-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 8px;
  opacity: 1;
  border-radius: 100%;
  border: 2px solid #fff;
}
.tt-ps-nav-pagination-bullets .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

/* Portfolio slider pagination dynamic bullets */
.tt-ps-nav-pagination-bullets-dynamic {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}
.tt-ps-nav-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
  transition: 0.2s transform, 0.2s left;
}
.tt-ps-nav-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.tt-ps-nav-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.tt-ps-nav-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.tt-ps-nav-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.tt-ps-nav-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

/* Portfolio slider pagination fraction */
.tt-ps-nav-pagination-fraction {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.tt-ps-nav-pagination-fraction .swiper-pagination-current {
  position: relative;
  top: -4px;
}
.tt-ps-nav-pagination-fraction .swiper-pagination-total {
  position: relative;
  bottom: -4px;
}

/* Portfolio slider pagination progressbar */
.tt-ps-nav-pagination-progressbar {
  position: absolute;
  left: 70px;
  right: 70px;
  width: auto;
  max-width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}
.tt-ps-nav-pagination-progressbar .swiper-pagination-progressbar-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scale(0);
  transform-origin: left top;
}

/* If portfolio slider item image is light (class "psi-image-is-light")
  =========================================== */
/* Make slider caption dark */
body.psi-light-image-on .tt-ps-caption-category,
body.psi-light-image-on .tt-ps-caption-title a {
  color: #111;
}

body.psi-light-image-on
  .tt-portfolio-slider-caption.psc-stroke
  .tt-ps-caption-title {
  -webkit-text-stroke-color: #111;
}

/* Make slider navigation dark */
body.psi-light-image-on .tt-ps-nav-pagination-fraction,
body.psi-light-image-on .tt-ps-nav-arrow {
  color: #111;
}
body.psi-light-image-on .tt-ps-nav-pagination-progressbar {
  background-color: rgb(0 0 0 / 20%);
}
body.psi-light-image-on
  .tt-ps-nav-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  background-color: #111;
}
body.psi-light-image-on
  .tt-ps-nav-pagination-bullets
  .swiper-pagination-bullet {
  border-color: #111;
}
body.psi-light-image-on
  .tt-ps-nav-pagination-bullets
  .swiper-pagination-bullet-active {
  background: #111;
}

/* Make magic cursor dark */
body.psi-light-image-on:not(.tt-ol-menu-open) #ball {
  border-color: #515151;
}
body.psi-light-image-on:not(.tt-ol-menu-open) #ball .ball-drag::before,
body.psi-light-image-on:not(.tt-ol-menu-open) #ball .ball-drag::after {
  color: #111;
}

/* Make header logo dark */
body.psi-light-image-on #tt-header .tt-logo-light {
  display: none;
}
body.psi-light-image-on #tt-header .tt-logo-dark {
  display: block;
}

/* Make overlay menu toggle button dark */
body.psi-light-image-on:not(.tt-ol-menu-open) .tt-ol-menu-toggle-btn-text {
  color: #111;
}
body.psi-light-image-on:not(.tt-ol-menu-open)
  .tt-ol-menu-toggle-btn
  span::before,
body.psi-light-image-on:not(.tt-ol-menu-open)
  .tt-ol-menu-toggle-btn
  span::after {
  background-color: #111;
}

/* Make classic menu links dark */
@media (min-width: 1025px) {
  body.psi-light-image-on .tt-main-menu-list > li > a,
  body.psi-light-image-on .tt-main-menu-list > li > .tt-submenu-trigger > a {
    color: #111;
  }
  body.psi-light-image-on .tt-main-menu-list.tt-mm-hover > li > a:hover,
  body.psi-light-image-on
    .tt-main-menu-list.tt-mm-hover
    > li
    > .tt-submenu-trigger
    > a:hover,
  body.psi-light-image-on .tt-main-menu-list.tt-mm-hover > li.active > a,
  body.psi-light-image-on
    .tt-main-menu-list.tt-mm-hover
    > li.active
    > .tt-submenu-trigger
    > a,
  body.psi-light-image-on
    .tt-main-menu-list.tt-mm-hover
    > li.tt-submenu-open
    > .tt-submenu-trigger
    > a {
    opacity: 1;
    color: #000;
  }
}
@media (max-width: 1024px) {
  body.psi-light-image-on:not(.tt-m-menu-open) .tt-m-menu-toggle-btn-text {
    color: #111;
  }
  body.psi-light-image-on:not(.tt-m-menu-open)
    .tt-m-menu-toggle-btn
    span::before,
  body.psi-light-image-on:not(.tt-m-menu-open)
    .tt-m-menu-toggle-btn
    span::after {
    background-color: #111;
  }
}

/* Make footer dark (if position is absolute) */
@media (min-width: 1025px) {
  body.psi-light-image-on #tt-footer.footer-absolute,
  body.psi-light-image-on #tt-footer.footer-absolute a,
  body.psi-light-image-on #tt-footer.footer-absolute .tt-btn-link > *,
  body.psi-light-image-on #tt-footer.footer-absolute .tt-btn-link > *::after {
    color: #111;
  }
  body.psi-light-image-on #tt-footer.footer-absolute .tt-btn-link::after {
    background-color: #111;
  }
}

/* ------------------------------------------------------------- *
   * Portfolio carousel
  /* ------------------------------------------------------------- */

.tt-portfolio-carousel {
  position: relative;
}

.tt-portfolio-carousel .swiper-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
}
.tt-portfolio-carousel .swiper-wrapper.swtr-smooth {
  /* Add transition when windows resize or orientation change */
  transition: transform 0.8s !important;
}

.tt-portfolio-carousel .swiper-slide {
  height: auto;
}
@media (min-width: 1025px) {
  .tt-portfolio-carousel .swiper-slide {
    width: auto;
  }
}

.tt-pc-ghost {
  position: absolute;
  font-size: calc(16px + 24vw);
  font-weight: 600;
  color: #fff;
  opacity: 0.02;
  line-height: 1;
  z-index: -1;
}
.tt-pc-ghost span {
  display: block;
}

/* Portfolio carousel item
  =========================== */
.tt-portfolio-carousel-item {
  position: relative;
  display: flex;
  line-height: 0;
}
@media (min-width: 1025px) {
  .tt-portfolio-carousel-item {
    margin-left: 7vw;
    margin-right: 7vw;
  }
}

.tt-pci-image-wrap {
  position: relative;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}
.tt-pci-image,
.tt-portfolio-carousel-item .tt-pci-video {
  position: relative;
}
video.tt-pci-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1025px) {
  .tt-pci-image,
  .tt-portfolio-carousel-item .tt-pci-video {
    height: 30vw;
  }
  .tt-pci-image {
    width: auto;
  }
  .tt-portfolio-carousel-item .tt-pci-video {
    width: 48vw;
  }
}
@media (max-width: 1024px) {
  .tt-pci-image,
  .tt-portfolio-carousel-item .tt-pci-video {
    width: 100%;
    height: 60vw;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* Portfolio carousel item hover 
  ================================= */
@media (min-width: 1025px) {
  .pci-hover .tt-slide-active .tt-portfolio-carousel-item .tt-pci-image-wrap,
  .pci-hover
    .tt-slide-active
    .tt-portfolio-carousel-item
    .tt-pci-caption-front {
    clip-path: inset(0 0 0 0);
    transition: all 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .pci-hover
    .tt-slide-active
    .tt-portfolio-carousel-item:hover
    .tt-pci-image-wrap,
  .pci-hover
    .tt-slide-active
    .tt-portfolio-carousel-item:hover
    .tt-pci-caption-front {
    clip-path: inset(3% 3% 3% 3%);
  }
  .pci-hover
    .tt-slide-active-start
    .tt-portfolio-carousel-item:hover
    .tt-pci-image-wrap,
  .pci-hover
    .tt-slide-active-start
    .tt-portfolio-carousel-item:hover
    .tt-pci-caption-front {
    clip-path: inset(0 0 0 0);
  }

  .pci-hover .tt-pci-image {
    transition: all 1s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .pci-hover .tt-portfolio-carousel-item:hover .tt-pci-image {
    transform: scale(1.05);
  }
}

/* Slide disabled (for non active slides)
  ================== */
.tt-portfolio-carousel-item {
  transition: all 0.5s ease-in-out;
}
.swiper-slide.tt-pcs-disabled .tt-portfolio-carousel-item {
  cursor: auto;
  pointer-events: none;
  opacity: 0.3;
  transform: scale(0.9);
}

/* Portfolio carousel item caption 
  =================================== */
.tt-pci-caption {
  position: absolute;
  top: 50%;
  width: 100%;
}
.tt-pci-caption-inner {
  position: relative;
}
.tt-pci-title {
  margin: 0;
  font-size: calc(16px + 3.2vw);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.2;
}
.tt-pci-categories {
  position: relative;
  margin-top: 30px;
}
.tt-pci-category {
  position: relative;
  display: inline-block;
  font-size: calc(13px + 0.1vw);
  color: #fff;
  line-height: 1.2;
}
.tt-pci-category ~ .tt-pci-category {
  margin-left: -4px;
}
.tt-pci-category ~ .tt-pci-category:not(:empty)::before {
  content: ", ";
}
.tt-pci-category ~ .tt-pci-category ~ .tt-pci-category ~ .tt-pci-category {
  visibility: hidden;
  width: 0;
}
.tt-pci-category
  ~ .tt-pci-category
  ~ .tt-pci-category
  ~ .tt-pci-category
  ~ .tt-pci-category {
  display: none;
}
.tt-pci-category
  + .tt-pci-category
  + .tt-pci-category
  + .tt-pci-category::before {
  content: "...";
  margin-left: 5px;
  visibility: visible;
}

@media (min-width: 1025px) {
  .tt-pci-caption {
    left: 0;
    transform: translate3d(-100px, -50%, 0);
  }
  .tt-pci-caption-front {
    position: absolute;
    top: 0;
    left: -1px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    overflow: hidden;
  }
  .tt-pci-caption-back {
    left: -1px;
    z-index: 1;
  }
}
@media (max-width: 1024px) {
  .tt-pci-caption {
    top: 40%;
    left: 5%;
    right: 5%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .tt-pci-caption-back {
    display: none;
  }
  .tt-pci-caption-front {
    z-index: 9;
  }
}

/* Portfolio carousel caption animation 
  ======================================== */
.swiper-slide .tt-pci-caption-inner,
.swiper-slide .tt-pci-categories {
  opacity: 0;
  visibility: hidden;
  transition: 1s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.swiper-slide.swiper-slide-prev .tt-pci-caption-inner,
.swiper-slide.swiper-slide-next .tt-pci-caption-inner,
.swiper-slide.swiper-slide-prev .tt-pci-categories,
.swiper-slide.swiper-slide-next .tt-pci-categories {
  transition: all 0.3s ease-out;
}
.swiper-slide.swiper-slide-active .tt-pci-caption-inner,
.swiper-slide.swiper-slide-active .tt-pci-categories {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

/* Offset */
.swiper-slide.swiper-slide-prev .tt-pci-caption-inner {
  transform: translate3d(-100px, 0, 0) skewX(10deg);
}
.swiper-slide.swiper-slide-next .tt-pci-caption-inner {
  transform: translate3d(100px, 0, 0) skewX(-10deg);
}
.swiper-slide.swiper-slide-prev .tt-pci-categories {
  transform: translate3d(-30px, 0, 0);
}
.swiper-slide.swiper-slide-next .tt-pci-categories {
  transform: translate3d(30px, 0, 0);
}

/* Delay */
.swiper-slide.swiper-slide-active .tt-pci-caption-inner {
  transition-delay: 0.6s;
}
.swiper-slide.swiper-slide-active .tt-pci-categories {
  transition-delay: 0.7s;
}

/* Portfolio carousel caption position 
  ======================================= */
/* Position center */
@media (min-width: 1025px) {
  .pci-caption-center .swiper-slide.tt-slide-active .tt-pci-caption-front {
    width: auto;
    margin-left: -10vw;
    margin-right: -10vw;
    pointer-events: none;
  }
}
.pci-caption-center .tt-pci-caption-front {
  overflow: visible;
}
.pci-caption-center .tt-pci-caption {
  top: 50%;
  left: 50%;
  padding: 0 5%;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
}
.pci-caption-center .tt-pci-title {
  font-weight: 600;
}
.pci-caption-center .tt-pci-caption-back {
  display: none !important;
}

/* Portfolio carousel caption styles
  ===================================== */
/* Style stroke */
@media (min-width: 1025px) {
  .pci-caption-stroke.pci-caption-center .tt-pci-caption-front .tt-pci-title,
  .pci-caption-stroke .tt-pci-caption-back .tt-pci-title {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: transparent;
  }
  .pci-caption-stroke .tt-pci-title {
    font-weight: bold;
    font-weight: 700;
  }
  .pci-caption-stroke.pci-caption-center .tt-pci-caption-front .tt-pci-title,
  .pci-caption-stroke.pci-caption-center .tt-pci-caption-back .tt-pci-title {
    -webkit-text-stroke-width: 2px;
    font-weight: bold;
    font-weight: 800;
  }

  /* If Firefox */
  body.is-firefox
    .pci-caption-stroke.pci-caption-center
    .tt-pci-caption-front
    .tt-pci-title,
  body.is-firefox .pci-caption-stroke .tt-pci-caption-back .tt-pci-title {
    -webkit-text-stroke-width: 2px;
  }
}

/* If item background image is light */
@media (min-width: 1025px) {
  .pci-image-is-light .tt-pci-caption-front .tt-pci-title {
    color: #111;
  }
  .pci-image-is-light .tt-pci-caption-front .tt-pci-category {
    color: #111;
  }
  .pci-image-is-light .tt-pci-image-wrap[class*="cover-opacity-"]::before {
    display: none !important;
  }
}

/* Portfolio carousel navigation 
  ================================= */
.tt-pc-navigation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4%;
  left: 50%;
  width: 300px;
  height: 60px;
  z-index: 1;
  transform: translate3d(-50%, 0, 0);
}
@media (max-width: 767px) {
  .tt-pc-navigation {
    width: 90%;
  }
}

.tt-pc-nav-prev,
.tt-pc-nav-next {
  position: absolute;
  top: 0;
  z-index: 20;
}
.tt-pc-nav-prev {
  left: 0;
}
.tt-pc-nav-next {
  right: 0;
}

.tt-pc-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.tt-pc-arrow-prev::after,
.tt-pc-arrow-next::after {
  line-height: 0;

  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.tt-pc-arrow-prev::after {
  content: "\f053";
}
.tt-pc-arrow-next::after {
  content: "\f054";
}

.tt-pc-arrow.tt-pc-arrow-disabled {
  cursor: auto;
  pointer-events: none;
}

.tt-portfolio-carousel .swiper-button-lock {
  display: none;
}

/* Portfolio carousel pagination 
  ================================= */
.tt-pc-pagination {
}

.tt-portfolio-carousel .swiper-pagination-lock {
  display: none;
}

/* Portfolio carousel pagination bullets */
.tt-pc-pagination-bullets {
  font-size: 0;
}
.tt-pc-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 8px;
  opacity: 1;
  border-radius: 100%;
  border: 2px solid #fff;
}
.tt-pc-pagination-bullets .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

/* Portfolio carousel pagination dynamic bullets */
.tt-pc-pagination-bullets-dynamic {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
  transition: 0.2s transform, 0.2s left;
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.tt-pc-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

/* Portfolio carousel pagination fraction */
.tt-pc-pagination-fraction {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.tt-pc-pagination-fraction .swiper-pagination-current {
  position: relative;
  top: -4px;
}
.tt-pc-pagination-fraction .swiper-pagination-total {
  position: relative;
  bottom: -4px;
}

/* Portfolio carousel pagination progressbar */
.tt-pc-pagination-progressbar {
  position: absolute;
  left: 70px;
  right: 70px;
  width: auto;
  max-width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}
.tt-pc-pagination-progressbar .swiper-pagination-progressbar-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scale(0);
  transform-origin: left top;
}

/* ------------------------------------------------------------- *
   * tt-Gallery
  /* ------------------------------------------------------------- */

.tt-gallery {
  position: relative;
}

/* tt-Gallery item 
  =================== */
.tt-gallery-item {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

/* tt-Gallery image */
.tt-gallery-image-wrap {
}
.tt-gallery-image {
  position: relative;
}
.tt-gallery-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Image parallax and hover zoom pseudo wraps (added by .js) */
.tt-grid:not(.ttgr-not-cropped) .tt-gallery-image .anim-image-parallax-wrap,
.tt-grid:not(.ttgr-not-cropped) .tt-gallery-image .anim-image-parallax-inner,
.tt-grid:not(.ttgr-not-cropped) .tt-gallery-image .tt-gallery-image-hover-zoom,
.tt-grid[class*="ttgr-layout-1-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-wrap,
.tt-grid[class*="ttgr-layout-1-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-inner,
.tt-grid[class*="ttgr-layout-1-"].ttgr-not-cropped
  .tt-gallery-image
  .tt-gallery-image-hover-zoom,
.tt-grid[class*="ttgr-layout-2-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-wrap,
.tt-grid[class*="ttgr-layout-2-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-inner,
.tt-grid[class*="ttgr-layout-2-"].ttgr-not-cropped
  .tt-gallery-image
  .tt-gallery-image-hover-zoom,
.tt-grid[class*="ttgr-layout-3-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-wrap,
.tt-grid[class*="ttgr-layout-3-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-inner,
.tt-grid[class*="ttgr-layout-3-"].ttgr-not-cropped
  .tt-gallery-image
  .tt-gallery-image-hover-zoom,
.tt-grid[class*="ttgr-layout-4-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-wrap,
.tt-grid[class*="ttgr-layout-4-"].ttgr-not-cropped
  .tt-gallery-image
  .anim-image-parallax-inner,
.tt-grid[class*="ttgr-layout-4-"].ttgr-not-cropped
  .tt-gallery-image
  .tt-gallery-image-hover-zoom,
.tt-grid .tt-gallery-video-wrap .anim-image-parallax-wrap,
.tt-grid .tt-gallery-video-wrap .anim-image-parallax-inner,
.tt-grid .tt-gallery-video-wrap .tt-gallery-image-hover-zoom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* tt-Gallery video */
.tt-gallery-video-wrap {
  position: relative;
}
.tt-gallery-video-wrap::before {
}
.tt-gallery-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.lg-autoplay-button {
  display: none !important;
}

/* tt-Gallery item hover 
  ========================= */
@media (min-width: 768px) {
  /* Clip path (effect only with gaps) */
  .tt-gallery.ttga-hover
    [class*="ttgr-gap-"]:not(.ttgr-gap-1)
    .tt-gallery-item-inner {
    clip-path: inset(0 0 0 0);
    overflow: hidden;
    transition: clip-path 0.8s cubic-bezier(0.07, 0.72, 0.29, 0.96);
  }
  .tt-gallery.ttga-hover
    [class*="ttgr-gap-"]:not(.ttgr-gap-1)
    .tt-gallery-item:hover
    .tt-gallery-item-inner {
    clip-path: inset(3% 3% 3% 3%);
  }

  /* Image zoom (".tt-gallery-image-hover-zoom" is added by .js) */
  .tt-gallery.ttga-hover .tt-gallery-image-hover-zoom {
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .tt-gallery.ttga-hover .tt-gallery-item:hover .tt-gallery-image-hover-zoom {
    transform: scale(1.05);
  }
}

/* ----------------------------------------------------------------------- *
   * Lightgallery (lightbox plugin)
   * More info: http://sachinchoolur.github.io/lightGallery)
  /* ----------------------------------------------------------------------- */

/* Lightgallery sub html (image caption) */
.lg-sub-html {
  font-size: 14px;
}
@media (max-width: 992px) {
  .lg-sub-html {
    padding: 10px 10px 40px 10px;
    font-size: 14px;
  }
}

.lg-sub-html p {
  display: inline-block;
  max-width: 1000px;
  font-size: 16px;
}

/* Lightgallery thumbnails improvements */
.lg-outer .lg-thumb {
  margin-left: auto;
  margin-right: auto;
}
.lg-outer .lg-thumb-item {
  position: relative;
  border: none;
  border-radius: 0;
  opacity: 0.4;
  transition: all 0.3s ease;
}
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  opacity: 1;
}
.lg-outer .lg-thumb-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Lightgallery autoplay progress bar improvements */
.lg-progress-bar {
  background-color: #333;
  height: 3px;
}
.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 3px;
}

/* hide next/prev buttons on small screens */
@media (max-width: 768px) {
  .lg-actions .lg-next,
  .lg-actions .lg-prev {
    display: none;
  }
}

/* ------------------------------------------------------------- *
   * Page nav
  /* ------------------------------------------------------------- */

.tt-page-nav {
  position: relative;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media screen and (max-width: 1900px) and (min-width: 1024px) {
  .tt-page-nav {
    max-width: 40vw;
  }
}

/* Page nav link */
.tt-pn-link {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
}

/* Page nav title */
.tt-pn-title {
  position: relative;
  display: block;
  left: 50%;
  font-size: clamp(42px, 6vw, 110px);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.6;
  opacity: 0.4;
  white-space: nowrap;
  transform: translate3d(-50%, 0, 0);
  transition: transform 0.6s cubic-bezier(0.51, 0.57, 0.17, 1);

  /* Ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
}
.tt-pn-hover-title {
  position: absolute;
  left: 50%;
  font-size: clamp(36px, 6vw, 110px);
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  line-height: 1.6;
  white-space: nowrap;
  transform: translate3d(-50%, 100%, 0);
  transition: transform 0.6s cubic-bezier(0.51, 0.57, 0.17, 1);
}
@media (max-width: 1024px) {
  .tt-pn-title:hover {
    opacity: 1;
  }
  .tt-pn-hover-title {
    display: none;
  }
}

@media (min-width: 1025px) {
  .tt-pn-link:hover .tt-pn-title {
    transform: translate3d(-50%, -100%, 0);
    transition-delay: 0.1s;
  }
  .tt-pn-link:hover .tt-pn-hover-title {
    transform: translate3d(-50%, 0, 0);
    transition-delay: 0.1s;
  }

  /* If the hover title is cloned (have multiple <span>'s) then animate it */
  .tt-page-nav .tt-pn-hover-title span {
    display: inline-flex;
    align-items: center;
    padding-right: 5vw;
    animation: marquee 3s linear infinite;
    animation-play-state: paused;
  }
  .tt-page-nav .tt-pn-link:hover .tt-pn-hover-title span {
    animation: marquee 3s linear infinite;
    animation-play-state: running;
  }
  @-webkit-keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
}

/* Page nav hover title separator (dot) */
.tt-pn-hover-title span::after {
  position: absolute;
  display: block;
  content: "";
  right: 1.9vw;
  top: 56%;
  width: 0.8vw;
  height: 0.8vw;
  background-color: #fff;
  border-radius: 100px;
  transform: translate3d(0, -50%, 0);
}

/* Page nav subtitle */
.tt-pn-subtitle {
  margin-top: 20px;
  font-size: calc(16px + 0.1vw);
  color: #fff;
}

/* Page nav image */
.tt-page-nav .tt-pn-image {
  display: none; /* disabled by default */
}
.tt-pn-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.tt-pn-image::before {
  position: absolute;
  display: block;
  content: "";
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: #040404;
  opacity: 0.1;
}
.tt-pn-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#magic-cursor.tt-pn-hover-on {
  z-index: -1;
}
#magic-cursor.tt-pn-hover-on #ball {
  overflow: hidden;
  border: none !important;
}

/* Video */
video.tt-pn-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Page nav styles 
  =================== */
/* Style stroke (no effect on small screens) */
@media (min-width: 1025px) {
  .tt-page-nav.tt-pn-stroke .tt-pn-title {
    font-weight: bold;
    font-weight: 800;
    opacity: 0.6;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    -webkit-text-fill-color: transparent;
  }

  /* If Firefox */
  body.is-firefox .tt-page-nav.tt-pn-stroke .tt-pn-title {
    -webkit-text-stroke-width: 2px;
  }
}

/* ------------------------------------------------------------- *
   * Logo wall
  /* ------------------------------------------------------------- */

.tt-logo-wall {
  margin: 0;
  padding: 0;
  margin-left: 2px;
  overflow: hidden;
}
.tt-logo-wall:after {
  content: "";
  clear: both;
  display: table;
}
.tt-logo-wall > li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 20%;
  margin-left: -2px;
  margin-bottom: -2px;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #212121;
}
.tt-logo-wall > li:before {
  display: block;
  content: "";
  padding-bottom: 100%;
}

.tt-logo-wall > li > a {
  display: flex;
}

/* Logo wall img */
.tt-logo-wall > li img {
  opacity: 0.5;
  transition: opacity 0.3s;
}
.tt-logo-wall > li:hover img {
  opacity: 1;
}

/* Logo wall columns */
.tt-logo-wall.cl-col-4 > li {
  width: 25%;
}
.tt-logo-wall.cl-col-3 > li {
  width: 33.33333%;
}
.tt-logo-wall.cl-col-2 > li {
  width: 50%;
}

/* Logo wall on smaller screens */
@media (max-width: 767px) {
  .tt-logo-wall > li {
    width: 50% !important;
  }
}

/* ------------------------------------------------------------- *
   * tt-Image
  /* ------------------------------------------------------------- */

.tt-image {
  overflow: hidden;
}

/* tt-image fixed height */
@media (min-width: 1025px) {
  .tt-image.tti-fixed-height img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* ------------------------------------------------------------- *
   * Content carousel
  /* ------------------------------------------------------------- */

.tt-content-carousel {
  position: relative;
}

.tt-content-carousel .swiper-wrapper {
}

.tt-content-carousel-item {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 1;
}

@media (min-width: 1025px) {
  .tt-content-carousel .swiper-slide {
    height: 100%;
    width: auto;
  }
  .tt-content-carousel-item {
    margin-left: 4vw;
    margin-right: 4vw;
  }
  .tt-content-carousel .tt-cc-image,
  .tt-content-carousel .swiper-slide .tt-cc-video {
    position: relative;
    width: 100%;
    height: 28vw;
    width: auto;
  }
}
@media (max-width: 1024px) {
  .tt-content-carousel-item {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  .tt-content-carousel .tt-cc-image,
  .tt-content-carousel .swiper-slide .tt-cc-video {
    position: relative;
    width: 100%;
    height: 60vw;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* Content carousel navigation 
  =============================== */
.tt-cc-nav-prev,
.tt-cc-nav-next {
  position: absolute;
  top: 50%;
  z-index: 20;
  transform: translateY(-50%);
}
.tt-cc-nav-prev {
  left: 2%;
}
.tt-cc-nav-next {
  right: 2%;
}

.tt-cc-nav-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.tt-cc-nav-prev .tt-cc-nav-arrow::after,
.tt-cc-nav-next .tt-cc-nav-arrow::after {
  position: relative;
  line-height: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.tt-cc-nav-prev .tt-cc-nav-arrow::after {
  content: "\f053";
}
.tt-cc-nav-next .tt-cc-nav-arrow::after {
  content: "\f054";
}

.tt-cc-nav-prev.tt-cc-nav-arrow-disabled .tt-cc-nav-arrow,
.tt-cc-nav-next.tt-cc-nav-arrow-disabled .tt-cc-nav-arrow {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

/* Hide content carousel navigation*/
.tt-content-carousel.cc-hide-navigation .tt-cc-nav-prev,
.tt-content-carousel.cc-hide-navigation .tt-cc-nav-next {
  display: none !important;
}

/* Content carousel pagination 
  =============================== */
.tt-cc-pagination {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, 0, 0);
}

/* Content carousel pagination bullets */
.tt-cc-pagination-bullets {
  font-size: 0;
}
.tt-cc-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 8px;
  opacity: 1;
  border-radius: 100%;
  border: 2px solid #fff;
}
.tt-cc-pagination-bullets .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

/* Content carousel pagination dynamic bullets */
.tt-cc-pagination-bullets-dynamic {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
  transition: 0.2s transform, 0.2s left;
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.tt-cc-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

/* Content carousel pagination fraction */
.tt-cc-pagination-fraction {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
}

.tt-cc-pagination-fraction .swiper-pagination-current {
  position: relative;
  top: -4px;
}
.tt-cc-pagination-fraction .swiper-pagination-total {
  position: relative;
  bottom: -4px;
}

/* Content carousel pagination progressbar */
.tt-cc-pagination-progressbar {
  width: 100%;
  max-width: 200px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}
@media (max-width: 768px) {
  .tt-cc-pagination-progressbar {
    max-width: 90%;
  }
}
.tt-cc-pagination-progressbar .swiper-pagination-progressbar-fill {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scale(0);
  transform-origin: left top;
}

/* Content carousel pagination position outside */
.tt-content-carousel.cc-pagination-outside {
  overflow: visible;
  margin-bottom: 100px;
}
.tt-content-carousel.cc-pagination-outside .tt-cc-pagination {
  bottom: -55px;
}

/* Hide content carousel pagination (for desktop only!) */
body:not(.is-mobile) .tt-content-carousel.cc-hide-pagination .tt-cc-pagination {
  display: none !important;
}

/* Content carousel shifted style (no effect on small screens!) 
  ================================== */
@media (min-width: 1025px) {
  .tt-content-carousel.cc-shifted .swiper-slide:nth-child(2n) {
    padding-top: 7%;
  }
}

/* ------------------------------------------------------------- *
   * Testimonials slider
  /* ------------------------------------------------------------- */

.tt-testimonials-slider {
  position: relative;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.tt-testimonials-slider .swiper-wrapper {
}

.tt-testimonials-slider .tt-ts-item {
  position: relative;
  display: block;
  margin: 0 auto;
  opacity: 0.1;
  transition: opacity 0.3s;
}
.tt-testimonials-slider .swiper-slide-active .tt-ts-item {
  opacity: 1;
}
.tt-testimonials-slider .tt-ts-text {
  font-size: calc(19px + 0.6vw);
  line-height: 1.6;
  font-style: italic;
}
.tt-testimonials-slider .tt-ts-subtext {
  margin-top: 30px;
  font-size: 19px;
  color: #999;
}

@media (min-width: 1025px) {
  .tt-testimonials-slider .swiper-slide {
    width: auto;
    max-width: 800px;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}
@media (max-width: 1024px) {
  .tt-testimonials-slider .swiper-slide {
    width: 100% !important;
    margin-left: 3vw;
    margin-right: 3vw;
  }
  .tt-testimonials-slider .tt-ts-item {
    padding: 20px;
  }
}

/* Testimonials slider navigation 
  ================================== */
.tt-ts-nav-prev,
.tt-ts-nav-next {
  position: absolute;
  top: 50%;
  z-index: 20;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  .tt-ts-nav-prev,
  .tt-ts-nav-next {
    display: none;
  }
}
.tt-ts-nav-prev {
  left: -10%;
}
.tt-ts-nav-next {
  right: -10%;
}

.tt-ts-nav-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.tt-ts-nav-prev .tt-ts-nav-arrow::after,
.tt-ts-nav-next .tt-ts-nav-arrow::after {
  position: relative;
  line-height: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.tt-ts-nav-prev .tt-ts-nav-arrow::after {
  content: "\f053";
}
.tt-ts-nav-next .tt-ts-nav-arrow::after {
  content: "\f054";
}

.tt-ts-nav-prev.tt-ts-nav-arrow-disabled .tt-ts-nav-arrow,
.tt-ts-nav-next.tt-ts-nav-arrow-disabled .tt-ts-nav-arrow {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

/* Hide testimonials slider navigation*/
.tt-testimonials-slider.ts-hide-navigation .tt-ts-nav-prev,
.tt-testimonials-slider.ts-hide-navigation .tt-ts-nav-next {
  display: none !important;
}

/* Testimonials slider pagination 
  ================================== */
.tt-ts-pagination {
  position: absolute;
  left: 50%;
  bottom: -80px;
  z-index: 1;
  transform: translate3d(-50%, 0, 0);
}
@media (max-width: 1024px) {
  .tt-ts-pagination {
    bottom: -50px;
  }
}

/* Hide testimonials slider pagination (for desktop only!) */
@media (min-width: 1025px) {
  .tt-testimonials-slider.ts-hide-pagination {
    margin-bottom: 0;
  }
  .tt-testimonials-slider.ts-hide-pagination .tt-ts-pagination {
    display: none !important;
  }
}

/* Testimonials slider pagination bullets */
.tt-ts-pagination-bullets {
  font-size: 0;
}
.tt-ts-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 8px;
  opacity: 1;
  border-radius: 100%;
  border: 2px solid #fff;
}
.tt-ts-pagination-bullets .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

/* Testimonials slider pagination dynamic bullets */
.tt-ts-pagination-bullets-dynamic {
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
  transition: 0.2s transform, 0.2s left;
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.tt-ts-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

/* Testimonials slider styles
  ============================== */
/* Full width */
.tt-testimonials-slider.ts-full-width {
  max-width: 100%;
}
.tt-testimonials-slider.ts-full-width .tt-ts-pagination {
  margin-top: 3%;
}
.tt-testimonials-slider.ts-full-width .tt-ts-nav-prev {
  left: 2%;
}
.tt-testimonials-slider.ts-full-width .tt-ts-nav-next {
  right: 2%;
}

/* ------------------------------------------------------------- *
   * Buttons
  /* ------------------------------------------------------------- */

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.tt-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: transparent;
  margin: 15px 15px 15px 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-weight: 500;
  overflow: hidden;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  z-index: 9;
}
.tt-btn:last-child {
  margin-right: 0;
}
.tt-btn > * {
  position: relative;
  color: #fff;
  transition: transform 0.3s;
}
.tt-btn > *,
.tt-btn > *::after {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 30px;
  line-height: 1;
}
.tt-btn > *::after {
  position: absolute;
  content: attr(data-hover);
  top: 100%;
  width: 100%;
  left: 0;
  padding-left: 0;
  padding-right: 0;
}
.tt-btn:hover > * {
  transform: translate3d(0, -100%, 0);
}

/* Button styles 
  ================= */
/* Button block */
.tt-btn-block {
  display: block;
  text-align: center;
}

/* Button round */
.tt-btn-round {
  width: 110px;
  height: 110px;
}
@media (max-width: 1024px) {
  .tt-btn-round {
    width: 100px;
    height: 100px;
  }
}
.tt-btn-round > * {
  max-width: 75%;
  padding: 0;
  line-height: 1.4;
}
.tt-btn-round > *::after {
  width: 100;
  line-height: 1.4;
}

/* Button primary */
.tt-btn-primary {
  background-color: #4c2122;
}
.tt-btn-primary > *,
.tt-btn-primary > *::after {
  color: #fff;
}
.tt-btn-primary:hover > * {
  color: #fff;
}

/* Button primary outline */
.tt-btn-primary-outline {
  box-shadow: inset 0 0 0 2px #4c2122;
}
.tt-btn-primary-outline > *,
.tt-btn-primary-outline > *::after {
  color: #4c2122;
}
.tt-btn-primary-outline:hover > * {
  color: #4c2122;
}

/* Button light */
.tt-btn-light {
  background-color: #fff;
}
.tt-btn-light > *,
.tt-btn-light > *::after {
  color: #111;
}
.tt-btn-light:hover > * {
  color: #111;
}

/* Button light outline */
.tt-btn-light-outline {
  box-shadow: inset 0 0 0 2px #fff;
}
.tt-btn-light-outline > *,
.tt-btn-light-outline > *::after {
  color: #fff;
}
.tt-btn-light-outline:hover > * {
  color: #fff;
}

/* Button dark */
.tt-btn-dark {
  background-color: #292929;
}
.tt-btn-dark > *,
.tt-btn-dark > *::after {
  color: #fff;
}
.tt-btn-dark:hover > * {
  color: #fff;
}

/* Button dark outline */
.tt-btn-dark-outline {
  box-shadow: inset 0 0 0 2px #222;
}
.tt-btn-dark-outline > *,
.tt-btn-dark-outline > *::after {
  color: #222;
}
.tt-btn-dark-outline:hover > * {
  color: #222;
}

/* Button link */
.tt-btn-link {
  align-items: center;
  border-radius: 0;
}
.tt-btn-link > *,
.tt-btn-link > *::after {
  padding: 0;
  line-height: 1.6;
  color: #fff;
}
.tt-btn-link:hover > * {
  color: #fff;
}

.tt-btn-link::after {
  display: inline-block;
  content: "";
  width: 40px;
  height: 1px;
  background-color: #fff;
  margin-top: 2px;
  margin-left: 15px;
}
body:not(.is-mobile) .tt-btn-link:hover::after {
  animation: swipe-line 0.8s cubic-bezier(0.475, 0.425, 0, 0.995) forwards;
}

@keyframes swipe-line {
  0% {
    transform-origin: right;
    transform: scale(1);
  }
  33% {
    transform-origin: right;
    transform: scaleX(0);
  }
  66% {
    transform-origin: left;
    transform: scaleX(0);
  }
  to {
    transform-origin: left;
    transform: scale(1);
  }
}

/* ------------------------------------------------------------- *
   * Social buttons
  /* ------------------------------------------------------------- */

.social-buttons {
}
.social-buttons ul {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-buttons ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 0 2px;
}
@media (max-width: 991px) {
  .social-buttons ul > li {
    margin: 0px;
  }
}
.social-buttons ul > li:first-child {
  margin-left: 0;
}
.social-buttons ul > li:last-child {
  margin-right: 0;
}

.social-buttons > ul > li a {
  display: block;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 15px;
  font-weight: 600;
  color: #eaeaea;
  transition: opacity 0.2s;
}
body:not(.tt-magic-cursor) .social-buttons > ul:hover > li a {
  opacity: 0.5;
}
body:not(.tt-magic-cursor) .social-buttons > ul > li a:hover {
  opacity: 1;
}

/* ------------------------------------------------------------- *
   * Accordion
  /* ------------------------------------------------------------- */

.tt-accordion {
  position: relative;
}

.tt-accordion-item {
  position: relative;
}

/* Accordion heading 
  ===================== */
.tt-accordion-heading {
  position: relative;
  padding: 20px 13% 20px 0;
  cursor: pointer;
}
.tt-accordion-item:first-child .tt-accordion-heading {
  padding-top: 0;
}
.tt-ac-head-title {
  margin: 0;
  font-size: 32px;
  line-height: 1.2;
}
@media (max-width: 991px) {
  .tt-ac-head-title {
    font-size: 28px;
  }
}

/* Accordion heading subtext */
.tt-accordion-subtext {
  margin-top: 10px;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 16px;
  background: linear-gradient(to right, #eace00 0%, #8bd4ef 18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: normal;
  color: #999;
}

/* Accordion heading caret */
.tt-accordion-caret-wrap {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
}
.tt-accordion-caret {
  position: relative;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
  z-index: 2;
  opacity: 0.3;
  transition: opacity 0.3s;
}
.tt-accordion-item:not(.active) .tt-accordion-heading:hover .tt-accordion-caret,
.tt-accordion-caret:hover {
  opacity: 1;
}

.tt-accordion-caret::before,
.tt-accordion-caret::after {
  position: absolute;
  display: block;
  content: "";
  background-image: linear-gradient(#eace00, #8bd4ef);
}
.tt-accordion-caret::before {
  width: 3px;
  height: 100%;
  transition: height 0.3s;
}
.tt-accordion-caret::after {
  width: 100%;
  height: 3px;
}
.tt-accordion-item.active .tt-accordion-caret::before {
  height: 0;
}

/* Accordion content 
  ===================== */
.tt-accordion-content {
  padding-bottom: 40px;
  color: #e0e0e0;
}
.tt-accordion-content:not(.is-open) {
  display: none;
  font-size: 22px;
}

.tt-accordion-content p:last-child {
  margin-bottom: 0;
}

/* Accordion styles 
  ==================== */
/* borders */
.tt-accordion.tt-ac-borders .tt-accordion-heading {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #eace00 0%, #8bd4ef 100%);
  border-image-slice: 1;
}

.tt-accordion.tt-ac-borders .tt-accordion-item:last-child {
  border-bottom: 1px solid rgb(125 125 125 / 40%);
}

/* Accordion sizes 
  =================== */
.tt-accordion.tt-ac-sm .tt-ac-head-title {
  font-size: calc(24px + 0.1vw);
}

.tt-accordion.tt-ac-lg .tt-ac-head-title {
  font-size: calc(24px + 1vw);
}

.tt-accordion.tt-ac-xlg .tt-accordion-heading {
  padding-top: 45px;
  padding-bottom: 45px;
}
.tt-accordion.tt-ac-xlg .tt-ac-head-title {
  font-size: calc(80px + 1.5vw);
}

.tt-accordion.tt-ac-xxlg .tt-accordion-heading {
  padding-top: 60px;
  padding-bottom: 60px;
}
.tt-accordion.tt-ac-xxlg .tt-ac-head-title {
  font-size: calc(24px + 2vw);
}

/* ------------------------------------------------------------- *
   * Magnetic wrap
  /* ------------------------------------------------------------- */

.magnetic-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------------------------------------------------------------- *
   * Blockquote
  /* ------------------------------------------------------------- */

blockquote {
  position: relative;
  margin: 35px 5%;
  padding-left: 40px;
  font-size: 24px;
  border-left: 4px solid #3e3e3e;
}
@media (max-width: 768px) {
  blockquote {
    margin-left: 0;
    margin-right: 0;
  }
}
blockquote.open-quote {
  border: none;
}
blockquote.open-quote::before {
  position: absolute;
  content: open-quote;
  top: 0;
  left: 0;
  font-size: 84px;
  font-weight: 300;
  color: #d2d2d2;
  line-height: 1;
}
blockquote footer {
  display: block;
  margin-top: 20px;
  font-size: 80%;
  color: #999;
}
blockquote footer::before {
  display: inline-block;
  content: "";
  width: 40px;
  height: 2px;
  background-color: #7d7d7d;
  margin: 0 10px 5px 0;
}

/* ------------------------------------------------------------- *
   * Figure
  /* ------------------------------------------------------------- */

figure {
  position: relative;
  margin: 0;
}

/* figure image */
figure img {
  width: 100%;
  height: auto;
}

/* figure caption */
figcaption {
  position: relative;
  max-width: 600px;
  padding: 15px;
  font-size: 14px;
  font-style: italic;
  color: #fff;
  z-index: 9;
  opacity: 0.8;
}
@media (min-width: 1025px) {
  figcaption {
    position: absolute;
    right: 5%;
    bottom: 5%;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
figcaption a {
  border-bottom: 1px dotted;
}
figcaption a:hover {
  opacity: 0.9;
}

/* ------------------------------------------------------------- *
   * Forms
  /* ------------------------------------------------------------- */

.tt-form-control {
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 5px 15px;
  font-size: 17px;
  font-weight: normal;
  color: #fff;
  border: 1px solid rgb(136 136 136 / 65%);
  border-radius: 3px;
}
.tt-form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: rgb(204 204 204 / 85%);
  outline: none;
  box-shadow: none;
}
.tt-form-control + .tt-form-control {
  margin-top: 20px;
}
.tt-form-control:disabled,
.tt-form-control[readonly] {
  background-color: rgb(176 176 176 / 6%);
  opacity: 0.4;
  cursor: not-allowed;
}

.tt-form-control:not(textarea):not([size]) {
  /* tt-form-control height */
  height: 56px;
}

.tt-form-text {
  display: block;
  margin-top: 5px;
  font-style: italic;
  color: #999;
}

label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: bold;
  font-weight: 500;
}

::-webkit-input-placeholder {
  color: #999 !important;
}
:-moz-placeholder {
  color: #999 !important;
}
::-moz-placeholder {
  color: #999 !important;
}
:-ms-input-placeholder {
  color: #999 !important;
}

/* form group */
.tt-form-group {
  margin-bottom: 30px;
}

/* checkbox/radio */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
}

/* textarea */
textarea,
textarea.tt-form-control {
  padding: 15px;
}

/* select */
select {
  cursor: pointer;
}
select option {
  background-color: #131212;
  color: #fff;
}
select option[value=""][disabled] {
  display: none;
}
select[multiple] option {
  background-color: transparent;
  color: black;
}
select option[disabled],
select[multiple] option[disabled] {
  color: #999;
}
select:required:invalid {
  color: #999;
}

/* range slider */
input[type="range"] {
  width: 100%;
}

/* Custom forms 
  ================= */
/* Custom checkbox/radio */
.tt-form-check,
.tt-form-radio {
  position: relative;
  display: block;
  margin: 0 0 8px 5px;
}

.tt-form-check input,
.tt-form-radio input {
  position: absolute;
  top: 50%;
  opacity: 0;
  cursor: pointer;
  transform: translate3d(0, -50%, 0);
}

.tt-form-check label,
.tt-form-radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;
  vertical-align: top;
  font-size: 17px;
  cursor: pointer;
}
.tt-form-check label::before,
.tt-form-radio label::before {
  position: absolute;
  top: 3px;
  left: -0;
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: "";
  background-color: #4e4e4e;
  border-radius: 3px;
  transition: background-color 0.2s;
}
.tt-form-check:hover label::before,
.tt-form-radio:hover label::before {
  background-color: #5a5a5a;
}
.tt-form-check label::after,
.tt-form-radio label::after {
  position: absolute;
  display: none;
  content: "";
  top: 8px;
  left: 7px;
  width: 4px;
  height: 6px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.tt-form-check input:checked ~ label::after,
.tt-form-radio input:checked ~ label::after {
  display: block;
}

.tt-form-radio label::before {
  border-radius: 100px;
}
.tt-form-radio label::after {
  top: 10px;
  left: 7px;
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 100%;
}

.tt-form-check input:disabled,
.tt-form-radio input:disabled {
  cursor: not-allowed;
}
.tt-form-check input:disabled ~ label,
.tt-form-radio input:disabled ~ label {
  cursor: not-allowed;
  opacity: 0.3;
}

.tt-form-check.tt-form-check-inline,
.tt-form-radio.tt-form-radio-inline {
  display: inline-block;
  margin-right: 10px;
}

/* Custom file upload */
.tt-form-file {
}
.btn-file {
  vertical-align: middle;
}
.btn-file > * input {
  position: absolute;
  left: 50%;
  width: 1%;
  opacity: 0;
}
.tt-file-info {
  width: 75%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: #777;
  line-height: 1;
}

/* Custom form with button inside */
.tt-form-btn-inside {
  position: relative;
}
.tt-form-btn-inside .tt-form-control {
  padding-right: 60px !important;
}
.tt-form-btn-inside button {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 60px;
  height: 54px;
  background-color: transparent;
  font-size: 19px;
  color: #999;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0 3px 3px 0;
}
.tt-form-btn-inside button:hover {
  color: #fff;
}

/* Form extra styles 
  ===================== */
/* form style filled */
.tt-form-filled label {
}
.tt-form-filled .tt-form-control {
  background-color: rgb(136 136 136 / 10%);
  color: #fff;
  border: none;
}
.tt-form-filled .tt-form-control:focus {
  box-shadow: inset 0 0 0 1px rgb(136 136 136 / 60%);
}
.tt-form-filled .tt-form-control:disabled,
.tt-form-filled .tt-form-control[readonly] {
  opacity: 0.3;
}

/* form style minimal */
.tt-form-minimal .tt-form-group {
}
.tt-form-minimal .tt-form-group label {
  display: none;
}
.tt-form-minimal .tt-form-control:not(select),
.tt-form-minimal select.tt-form-control:not([multiple]) {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-radius: 0;
}
.tt-form-minimal .tt-form-control:focus {
  border-color: #fff;
}
.tt-form-minimal .tt-form-control:disabled,
.tt-form-minimal .tt-form-control[readonly] {
  opacity: 0.2;
}
.tt-form-minimal .tt-form-control,
.tt-form-minimal .tt-form-control select {
  border-color: rgb(136 136 136 / 60%);
}

.tt-form-minimal ::-webkit-input-placeholder {
  color: #fff !important;
}
.tt-form-minimal :-moz-placeholder {
  color: #fff !important;
}
.tt-form-minimal ::-moz-placeholder {
  color: #fff !important;
}
.tt-form-minimal :-ms-input-placeholder {
  color: #fff !important;
}

/* form hide labels */
.tt-hide-labels .tt-form-group label {
  display: none;
}

/* Form sizes 
  ============== */
/* form sm */
.tt-form-sm .tt-form-control:not(textarea):not([size]),
.tt-form-control.tt-form-sm,
.tt-form-btn-inside .tt-form-control.tt-form-sm + button {
  height: 40px !important;
  font-size: 75%;
}
.tt-form-sm .tt-form-btn-inside button {
  height: 38px !important;
}

/* form lg */
.tt-form-lg .tt-form-control:not(textarea):not([size]),
.tt-form-control.tt-form-lg,
.tt-form-btn-inside .tt-form-control.tt-form-lg + button {
  height: 70px !important;
}
.tt-form-lg .tt-form-btn-inside button {
  height: 68px !important;
}

/* -------------------------------------------------------------------- *
   * Contact info
  /* -------------------------------------------------------------------- */

.tt-contact-info {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 17px;
}

.tt-contact-info > li {
  padding: 3px 0;
}

.tt-ci-icon {
  display: inline-block;
  margin-right: 10px;
  font-size: 80%;
}
.tt-contact-info a {
  display: inline;
  transition: color 0.2s;
}
.tt-contact-info a:hover {
  color: #fff;
}

/* Social buttons */
.tt-contact-info .social-buttons {
  display: inline-block;
  margin-top: 15px;
  margin-left: -13px;
}

/* -------------------------------------------------------------------- *
   * Background video
   * Note: position "relative" or "absolute" required on parent element!
  /* -------------------------------------------------------------------- */

.tt-bg-video-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
video.tt-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ------------------------------------------------------------- *
   * Background noise
  /* ------------------------------------------------------------- */

.bg-noise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.bg-noise:before {
  position: absolute;
  content: "";
  top: -10rem;
  left: -10rem;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  z-index: -1;
  pointer-events: none;
  background-image: url(../images/bg-noise.png);
  background-position: 50%;
  animation: BgNoise 1s steps(2) infinite;
}
@keyframes BgNoise {
  0% {
    transform: translate3d(0, 9rem, 0);
  }
  10% {
    transform: translate3d(-1rem, -4rem, 0);
  }
  20% {
    transform: translate3d(-8rem, 2rem, 0);
  }
  30% {
    transform: translate3d(9rem, -9rem, 0);
  }
  40% {
    transform: translate3d(-2rem, 7rem, 0);
  }
  50% {
    transform: translate3d(-9rem, -4rem, 0);
  }
  60% {
    transform: translate3d(2rem, 6rem, 0);
  }
  70% {
    transform: translate3d(7rem, -8rem, 0);
  }
  80% {
    transform: translate3d(-9rem, 1rem, 0);
  }
  90% {
    transform: translate3d(6rem, -5rem, 0);
  }
  to {
    transform: translate3d(-7rem, 0, 0);
  }
}

/* ------------------------------------------------------------- *
   * tt-Embed
  /* ------------------------------------------------------------- */

.tt-embed {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  overflow: hidden;
}
.tt-embed::before {
  display: block;
  content: "";
  padding-bottom: 56.25%;
}
.tt-embed .tt-embed-item,
.tt-embed embed,
.tt-embed iframe,
.tt-embed object,
.tt-embed video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* ------------------------------------------------------------- *
   * Scrolling text
  /* ------------------------------------------------------------- */

.tt-scrolling-text {
  display: flex;
  position: relative;
  pointer-events: none;
}
.tt-scrolling-text-inner {
  padding-left: 2vw;
  font-size: 7.5vw;
  line-height: 1.1;
  font-weight: bold;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  animation-name: tt_scrolling_text;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  /* animation-duration is handled by js. */
}
.tt-scrolling-text-inner:after {
  content: attr(data-text);
}

@keyframes tt_scrolling_text {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Animation direction reverse. */
.tt-scrolling-text.scr-text-reverse .tt-scrolling-text-inner {
  animation-direction: reverse;
}

/* Scrolling text styles 
  ========================= */
/* Style stroke */
@media (min-width: 1025px) {
  .tt-scrolling-text.scr-text-stroke .tt-scrolling-text-inner {
    font-weight: bold;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #aaa;
    -webkit-text-fill-color: transparent;
  }
}

/* ------------------------------------------------------------- *
   * Pagination (tt-pagination)
  /* ------------------------------------------------------------- */

.tt-pagination {
  display: flex;
  margin-top: 40px;
  padding: 40px 3%;
}
body.tt-boxed .tt-wrap .tt-pagination {
  padding-left: 0;
  padding-right: 0;
}

.tt-pagin-numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tt-pagin-item {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  opacity: 0.4;
  transition: opacity 0.3s;
}
.tt-pagin-item:hover,
.tt-pagin-item.active {
  opacity: 1;
}

.tt-pagin-prev {
  margin-right: 3%;
}
.tt-pagin-next {
  margin-left: 3%;
}

/* Align center */
.tt-pagination.tt-pagin-center {
  justify-content: space-between;
  text-align: center;
}

/* ------------------------------------------------------------- *
   * Sidebar (classic)
  /* ------------------------------------------------------------- */

.tt-sidebar {
  margin-left: 40px;
}
@media (max-width: 1600px) {
  .tt-sidebar {
    margin-left: 10px;
  }
}
@media (max-width: 991px) {
  .tt-sidebar {
    margin: 60px 0 0 0 !important;
  }
}

/* Sidebar left */
.tt-row.tt-lg-row-reverse .tt-sidebar {
  margin-left: 0;
  margin-right: 40px;
}
@media (max-width: 1600px) {
  .tt-row.tt-lg-row-reverse .tt-sidebar {
    margin-right: 10px;
  }
}

/* ------------------------------------------------------------- *
   * Sliding sidebar
  /* ------------------------------------------------------------- */

@media (max-width: 1400px) and (min-width: 769px) {
  body:not(.is-mobile).tt-sliding-sidebar-left-on #content-wrap {
    padding-left: 40px;
  }
  body:not(.is-mobile).tt-sliding-sidebar-right-on #content-wrap {
    padding-right: 40px;
  }
  body:not(.is-mobile).tt-sliding-sidebar-left-on .tt-header-inner {
    padding-left: 50px;
  }
  body:not(.is-mobile).tt-sliding-sidebar-right-on .tt-header-inner {
    padding-right: 50px;
  }
}

/* Sliding sidebar */
.tt-sliding-sidebar-wrap {
  position: relative;
  z-index: 9999;
}

.tt-sliding-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 400px;
  height: 100vh;
  z-index: 2;
  background-color: #1b1b1b;
  overflow: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.4s ease-in-out;
}
@media (max-width: 768px) {
  .tt-sliding-sidebar {
    width: 90%;
  }
}
body.tt-sliding-sidebar-open .tt-sliding-sidebar {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}
.tt-sliding-sidebar-inner {
  height: 100%;
  overflow-y: auto;
  padding: 10% 8%;
}
body:not(.is-mobile) .tt-sliding-sidebar-inner {
  width: calc(100% + 17px);
  margin-right: 17px;
}

.tt-sliding-sidebar-close {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 85%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}
body.tt-sliding-sidebar-open .tt-sliding-sidebar-close {
  opacity: 1;
  visibility: visible;
}

/* Sliding sidebar trigger 
  =========================== */
.tt-sliding-sidebar-trigger {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 40px;
  height: 100vh;
  background-color: #1b1b1b;
  font-size: 20px;
  color: #808080;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, width 0.3s;
}
.tt-sliding-sidebar-trigger:hover {
  width: 54px;
  background-color: #222;
  color: #fff;
}
.tt-sliding-sidebar-trigger .tt-ss-icon-close {
  display: none;
}

/* Sliding sidebar trigger on mobile devices and small screens */
body.is-mobile .tt-sliding-sidebar-trigger {
  top: auto;
  left: auto;
  right: 15px !important;
  bottom: 15px !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #333 !important;
  color: #fff;
  border-radius: 100px;
  z-index: 2;
}
body.is-mobile.tt-sliding-sidebar-open .tt-sliding-sidebar-trigger .tt-ss-icon {
  display: none;
}
body.is-mobile.tt-sliding-sidebar-open
  .tt-sliding-sidebar-trigger
  .tt-ss-icon-close {
  display: block;
}

@media (max-width: 768px) {
  .tt-sliding-sidebar-trigger {
    top: auto;
    left: auto;
    right: 15px !important;
    bottom: 15px !important;
    width: 50px !important;
    height: 50px !important;
    background-color: #333 !important;
    color: #fff;
    border-radius: 100px;
    z-index: 2;
  }
  body.tt-sliding-sidebar-open .tt-sliding-sidebar-trigger .tt-ss-icon {
    display: none;
  }
  body.tt-sliding-sidebar-open .tt-sliding-sidebar-trigger .tt-ss-icon-close {
    display: block;
  }
}

/* Sliding sidebar position right 
  ================================== */
.tt-ss-right .tt-sliding-sidebar {
  left: auto;
  right: 0;
  transform: translate3d(100%, 0, 0);
}
.tt-ss-right .tt-sliding-sidebar-trigger {
  left: auto;
  right: 0;
}

/* ------------------------------------------------------------- *
   * Sidebar widgets
  /* ------------------------------------------------------------- */

.sidebar-widget {
  margin-bottom: 60px;
}

/* Sidebar headings */
.sidebar-heading {
  position: relative;
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 700;
}
.sidebar-heading:after {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  bottom: -15px;
  width: 35px;
  height: 2px;
  background-color: #555;
}

/* Sidebar search 
  ================== */
.sidebar-search {
}

/* Sidebar categories 
  ====================== */
.sidebar-categories {
}
.sidebar-categories > ul > li {
  padding: 0;
}
.sidebar-categories > ul > li > a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  color: #b1b1b1;
  line-height: 1;
  padding: 12px 0px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-categories > ul > li > a:hover {
  color: #fff;
}
.sidebar-categories > ul > li > a span {
  position: relative;
  float: right;
  top: -5px;
  background-color: rgb(133 133 133 / 22%);
  padding: 8px 9px 8px 9px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  color: #ccc;
  overflow: hidden;
  z-index: 1;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
}
.sidebar-categories > ul > li > a:hover span {
  background-color: rgb(133 133 133 / 40%);
  color: #fff;
}

/* Sidebar post list 
  ===================== */
.sidebar-post-list {
}
.sidebar-post-list > ul > li {
  list-style: none;
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.sidebar-post-list > ul > li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}

.sidebar-post-data {
}
.sidebar-post-title {
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: bold;
  font-weight: 500;
  line-height: 1.4;
}
.sidebar-post-title a {
  color: #b1b1b1;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.sidebar-post-title a:hover {
  background-size: 100% 96%;
  color: #fff;
}
.sidebar-post-author {
  display: block;
  font-size: 14px;
  color: #777;
  line-height: 15px;
}
.sidebar-post-author a {
  color: #777;
}
.sidebar-post-author a:hover {
  opacity: 0.8;
}
.sidebar-post-date {
  display: block;
  font-size: 13px;
  color: #777;
}

/* Sidebar post comments list 
  ============================== */
.sidebar-comments-list {
}
.sidebar-comments-list ul {
  margin: 0;
}

/* Sidebar comment */
.sidebar-comment {
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.sidebar-comment:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

/* Sidebar comment meta */
.sidebar-comment-meta {
  overflow: hidden;
}

/* Sidebar comment data */
.sidebar-comment-data {
}
.sidebar-comment-author {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.sidebar-comment-author a {
  position: relative;
  color: #ccc;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.sidebar-comment-author a:hover {
  background-size: 100% 96%;
  color: #fff;
}
.sidebar-comment-date {
  display: block;
  font-size: 13px;
  color: #777;
}

/* Sidebar comment text */
.sidebar-comment-text {
  margin-top: 5px;
  font-size: 16px;
  line-height: 1.4;
  font-style: italic;
  color: #fff;
}
.sidebar-comment-text a {
  color: #999;
}
.sidebar-comment-text a:hover {
  opacity: 0.8;
}
.sidebar-comment-text p {
  margin-bottom: 0;
}

/* Sidebar tags 
  ================ */
.sidebar-tags {
}
.sidebar-tags-list {
}
.sidebar-tags-list::after {
  /* Clear */
  content: "";
  display: table;
  clear: both;
}
.sidebar-tags-list > ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar-tags-list > ul > li {
  margin: 3px;
}
.sidebar-tags-list a {
  position: relative;
  display: block;
  background-color: rgb(160 160 160 / 12%);
  padding: 2px 10px;
  font-size: 13px;
  font-weight: 300;
  color: #cacaca;
  overflow: hidden;
  z-index: 1;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}
.sidebar-tags-list a:hover {
  background-color: rgb(160 160 160 / 35%);
  color: #fff;
}

/* Sidebar meta 
  ================ */
.sidebar-meta {
}
.sidebar-meta ul > li > a {
  position: relative;
  margin: 3px 0;
  font-size: 16px;
  color: #b1b1b1;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.sidebar-meta ul > li > a:hover {
  background-size: 100% 96%;
  color: #fff;
}

/* ------------------------------------------------------------- *
   * Blog list
  /* ------------------------------------------------------------- */

#blog-list {
}

/* Blog list item
  ================== */
.blog-list-item {
  position: relative;
  margin-bottom: 80px;
}

/* Blog list item image */
.bli-image-wrap {
}
.bli-image {
  position: relative;
  display: block;
  overflow: hidden;
}
.bli-image img {
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.bli-image:hover img {
  transform: scale(1.05);
}

#blog-list.bli-image-cropped .bli-image {
  position: relative;
  background-color: rgba(130, 130, 130, 0.1);
  padding-bottom: 55%;
}
#blog-list.bli-image-cropped .bli-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

/* Blog list item info */
.bli-info {
  position: relative;
  padding-top: 30px;
}

.bli-categories {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 16px;
  color: #999;
}
.bli-categories a {
  position: relative;
  display: inline-block;
}
.bli-categories a:hover {
  opacity: 0.8;
}
.bli-categories a ~ a:not(:empty)::before {
  content: ", ";
}

.bli-title {
  margin-bottom: 20px;
  font-size: clamp(28px, 3vw, 42px);
  font-weight: bold;
  font-weight: 600;
  color: #fff;
  line-height: 1.6;
}
.bli-title a {
  color: #fff;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.bli-title a:hover {
  background-size: 100% 96%;
}

.bli-meta {
  font-size: 15px;
  color: #999;
}
.bli-meta a {
  position: relative;
  padding-bottom: 2px;
  color: #999;
}
.bli-meta a:hover {
  opacity: 0.8;
}

/* Blog list item description */
.bli-desc {
  margin-top: 40px;
  font-weight: 300;
  font-size: 19px;
  color: #bbb;
}

/* Blog list item read more button */
.bli-read-more {
  margin: 20px 0 0 0;
}

/* Blog list compact 
  ===================== */
@media (min-width: 1025px) {
  #blog-list.bli-compact .blog-list-item {
    display: flex;
    margin-bottom: 100px;
  }
  #blog-list.bli-compact .bli-image-wrap {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  #blog-list.bli-compact .bli-image {
    padding-bottom: 0;
  }
  #blog-list.bli-compact .bli-image,
  #blog-list.bli-compact .anim-image-parallax-wrap {
    height: 100%;
  }
  #blog-list.bli-compact .bli-info {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
    padding-top: 0;
  }
  #blog-list.bli-compact .bli-image-wrap + .bli-info {
    padding: 0 0 0 5%;
  }
  #blog-list.bli-compact .bli-categories {
    margin-bottom: 10px;
  }
  #blog-list.bli-compact .bli-title {
    margin-bottom: 15px;
    font-size: clamp(28px, 3vw, 38px);
  }
  #blog-list.bli-compact .bli-desc {
    margin-top: 15px;
  }
  #blog-list.bli-compact .bli-read-more {
    margin: 20px 0 0 0;
  }

  /* Counter (no effect if sidebar exist!) */
  body:not(.tt-sidebar-on) #blog-list.bli-compact {
    counter-reset: bli-compact-counter;
  }
  body:not(.tt-sidebar-on) #blog-list.bli-compact .bli-info::before {
    position: absolute;
    counter-increment: bli-compact-counter;
    content: "" counter(bli-compact-counter, decimal-leading-zero);
    top: -20%;
    left: 30%;
    line-height: 1;
    font-size: clamp(40px, 37vw, 360px);
    font-weight: normal;
    color: #fff;
    opacity: 0.1;
    z-index: -1;
  }

  /* If sidebar exist */
  body.tt-sidebar-on #blog-list.bli-compact .bli-title {
    font-size: 30px;
  }
}

/* ------------------------------------------------------------- *
   * Blog interactive
  /* ------------------------------------------------------------- */

.blog-interactive {
}

.blog-interactive-item {
  position: relative;
  margin-bottom: 100px;
}
@media (max-width: 1400px) {
  .blog-interactive-item {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media (max-width: 768px) {
  .blog-interactive-item {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Blog interactive item counter */
@media (min-width: 769px) {
  .blog-interactive {
    counter-reset: bi-item-counter;
  }
  .blog-interactive-item::before {
    position: absolute;
    counter-increment: bi-item-counter;
    content: "" counter(bi-item-counter, decimal-leading-zero);
    top: 45%;
    right: 0;
    line-height: 1;
    font-size: clamp(40px, 12vw, 220px);
    font-weight: normal;
    color: #fff;
    opacity: 0.1;
    z-index: -1;
    transform: translateY(-50%);
  }
}
@media (max-width: 1400px) {
  .blog-interactive-item::before {
    right: 3%;
  }
}

/* Blog interactive item image */
.bi-item-image {
  position: relative;
  display: block;
  overflow: hidden;
}
.bi-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 769px) {
  .blog-interactive-item .bi-item-image {
    display: none;
  }
  #ball .bi-item-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  #ball .bi-item-image::before {
    position: absolute;
    display: block;
    content: "";
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background-color: #040404;
    opacity: 0.2;
  }
}
@media (max-width: 768px) {
  .bi-item-image {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 60%;
  }
  .bi-item-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

/* Blog interactive item categories */
.bi-item-categories {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 16px;
  color: #999;
}
.bi-item-categories a {
  position: relative;
  display: inline-block;
}
.bi-item-categories a:hover {
  opacity: 0.8;
}
.bi-item-categories a ~ a:not(:empty)::before {
  content: ", ";
}

/* Blog interactive item title */
.bi-item-title {
  margin-bottom: 30px;
  font-size: clamp(28px, 3vw, 50px);
  font-weight: bold;
  font-weight: 600;
  color: #fff;
  line-height: 1.4;
}
@media (min-width: 769px) {
  .blog-interactive-item:not(.bi-item-image-on) .bi-item-title {
    padding-right: 23%;
  }
}
@media (max-width: 768px) {
  .bi-item-title {
    margin-bottom: 15px;
  }
}
.bi-item-title a {
  display: block;
  color: #fff;
}

.blog-interactive-item:not(.bi-item-image-on) .bi-item-title a {
  color: #fff;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.blog-interactive-item:not(.bi-item-image-on) .bi-item-title a:hover {
  background-size: 100% 96%;
  color: #fff;
}
@media (min-width: 769px) {
  .blog-interactive-item.bi-item-image-on .bi-item-title a {
    padding-right: 23%;
  }
}

/* Blog interactive item meta */
.bi-item-meta {
  font-size: 15px;
  color: #999;
}
.bi-item-meta a {
  position: relative;
  padding-bottom: 2px;
  color: #999;
}
.bi-item-meta a:hover {
  opacity: 0.8;
}

/* Magic cursor behavior */
#magic-cursor.blog-interactive-hover-on {
  z-index: -1;
}
#magic-cursor.blog-interactive-hover-on #ball {
  overflow: hidden;
  border: none !important;
}

/* Blog interactive item styles 
  ================================ */
/* Borders */
@media (min-width: 769px) {
  .blog-interactive.bi-borders .blog-interactive-item {
    margin: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid rgb(144 144 144 / 35%);
  }
  .blog-interactive.bi-borders .blog-interactive-item:first-child {
    border-top: 1px solid rgb(144 144 144 / 35%);
  }
}

/* ------------------------------------------------------------- *
   * Blog single post
  /* ------------------------------------------------------------- */

/* blog single inner */
.blog-single-inner {
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
}

/* if page boxed layout enabled */
body.tt-boxed .blog-single-inner.tt-wrap {
  padding-left: 15px;
  padding-right: 15px;
}

/* Blog single post 
  ==================== */
.tt-blog-post {
  position: relative;
}

/* blog single post content */
.tt-blog-post-content {
}

.tt-blog-post-content p {
  color: #b9b9b9;
}

/* blog single post image */
.tt-blog-post-image {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.tt-bpi-link {
  position: relative;
  display: block;
}
.tt-blog-post-image img {
}
.tt-bpi-caption {
}

/* blog single tags */
.tt-blog-post-tags {
  padding: 40px 0;
  font-size: 16px;
  font-weight: bold;
  font-weight: 700;
  overflow: hidden;
  margin-top: 60px;
  overflow: hidden;
  border-top: 1px solid rgba(150, 150, 150, 0.2);
}
.tt-blog-post-tags span {
  color: #bbb;
}
.tt-blog-post-tags > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tt-blog-post-tags > ul > li {
  float: left;
  margin: 3px;
}
.tt-blog-post-tags a {
  position: relative;
  display: block;
  background-color: rgb(160 160 160 / 12%);
  padding: 2px 10px;
  font-size: 13px;
  font-weight: 300;
  color: #cacaca;
  overflow: hidden;
  z-index: 1;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}
.tt-blog-post-tags a:hover {
  background-color: rgb(160 160 160 / 35%);
  color: #fff;
}

/* Blog single post share 
  ========================== */
.tt-blog-post-share {
}
.tt-blog-post-share > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tt-blog-post-share > ul > li {
  display: inline-block;
}
.tt-bps-text {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
  font-weight: 700;
}

.tt-blog-post-share .social-buttons {
  margin-left: -20px;
}
.tt-blog-post-share .social-buttons > ul > li a {
  font-size: 19px;
}

/* Blog single post nav 
  ======================== */
.tt-blog-post-nav {
  margin-top: 60px;
  padding-top: 60px;
  overflow: hidden;
  border-top: 1px solid rgba(150, 150, 150, 0.2);
}

.tt-bp-nav-col {
  float: left;
  width: 50%;
}
.tt-bp-nav-left {
  padding-right: 5%;
  border-right: 1px solid rgba(150, 150, 150, 0.2);
}
.tt-bp-nav-left span {
  margin-right: 10px;
}
.tt-bp-nav-right {
  padding-left: 5%;
  text-align: right;
}
.tt-bp-nav-right span {
  margin-left: 10px;
}

.tt-bp-nav-text {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  font-weight: 500;
  color: #999;
}
@media (max-width: 680px) {
  .tt-bp-nav-text {
    margin-bottom: 0;
    font-size: 19px;
    color: #fff;
  }
}
.tt-bp-nav-title {
  margin: 0;
  font-size: 21px;
  line-height: 1.6;
}
@media (max-width: 680px) {
  .tt-bp-nav-title {
    display: none;
  }
}
.tt-bp-nav-title a {
  color: #fff;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.tt-bp-nav-title a:hover {
  background-size: 100% 96%;
  color: #fff;
}

/* Blog single post comments 
  ============================= */
#tt-blog-post-comments {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid rgba(150, 150, 150, 0.2);
}

.tt-bpc-heading {
  margin: 0 0 30px 0;
  font-size: 24px;
}

/* tt-Comments list */
.tt-comments-list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}
.tt-comments-list > li {
  margin-top: 40px;
}

/* tt-Comments */
.tt-comment {
}

/* tt-Comments avatar */
.tt-comment-avatar {
  position: relative;
  display: inline-block;
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 100px;
}
.tt-comment-avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.tt-comment-avatar:hover {
  opacity: 0.9;
}

/* tt-Comments pody */
.tt-comment-body {
  position: relative;
  display: table;
}
@media (max-width: 768px) {
  .tt-comment-body {
    display: block;
  }
}

/* tt-Comments meta */
.tt-comment-meta {
  margin-right: 70px;
}

.tt-comment-heading {
  margin: 0 0 5px 0;
  text-transform: none;
  letter-spacing: 0;
  font-size: 17px;
  color: #ccc;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tt-comment-heading a {
  position: relative;
  color: #ccc;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.tt-comment-heading a:hover {
  background-size: 100% 96%;
  color: #fff;
}

.tt-comment-time {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  color: #999;
}

/* tt-Comments text */
.tt-comment-text {
  clear: both;
  color: #b9b9b9;
}
@media (max-width: 768px) {
  .tt-comment-text {
    font-size: 16px;
  }
}

/* tt-Comments reply */
.tt-comment-reply {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right;
  font-size: 14px;
  font-style: italic;
  z-index: 9;
}
@media (max-width: 768px) {
  .tt-comment-reply {
    top: 0;
    right: 0;
  }
}
.tt-comment-reply a {
  position: relative;
  color: #888;
  display: inline;
  background-repeat: no-repeat;
  background-image: -o-linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: linear-gradient(
    transparent calc(100% - 1px),
    currentColor 1px
  );
  background-image: -ms-linear-gradient(transparent 96%, currentColor 1px);
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-size: 0 96%;
}
.tt-comment-reply a:hover {
  background-size: 100% 96%;
  color: #fff;
}

/* blog single post comment form */
.tt-post-comment-form-heading {
  margin-bottom: 50px;
  font-size: 28px;
}
#tt-post-comment-form {
  margin-top: 140px;
}
@media (max-width: 768px) {
  #tt-post-comment-form {
    margin-top: 80px;
  }
}
#tt-post-comment-form .tt-post-comment-reminder {
  margin-top: 30px;
  line-height: 1.2;
}
#tt-post-comment-form .tt-post-comment-reminder label {
  padding-top: 3px;
  font-size: 15px;
  font-weight: normal;
  color: #888;
  text-transform: unset;
  letter-spacing: 0;
}

/* ------------------------------------------------------------- *
   * Footer
  /* ------------------------------------------------------------- */

#tt-footer {
  position: relative;
  width: 100%;
  font-size: 14px;
  z-index: 2;
}

.tt-footer-inner {
  position: relative;
}
body:not(.tt-boxed) .tt-footer-inner,
.tt-footer-inner:not(.tt-wrap) {
  padding-left: 3.5%;
  padding-right: 3.5%;
}

#tt-footer a {
  font-size: 14px;
  color: #fff;
}

#tt-footer .tt-btn {
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Footer copyright */
#tt-footer .tt-copyright {
  color: #888;
}
#tt-footer .tt-copyright a {
  color: #888;
}
#tt-footer .tt-copyright a:hover {
}

/* For desktop only 
  ==================== */
@media (min-width: 1025px) {
  #tt-footer {
    padding: 30px 0;
  }
  #tt-footer.footer-absolute {
    position: absolute;
    left: 0;
    bottom: 4%;
    padding: 0;
    pointer-events: none;
  }
  #tt-footer.footer-absolute a {
    pointer-events: initial;
  }

  .tt-footer-inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  /* Footer columns */
  .footer-col {
    flex: 1;
    padding: 0 15px;
    margin: 0 5px;
  }
  .footer-col:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .footer-col:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .footer-col-inner {
  }

  /* Align footer column content */
  .footer-col.tt-align-top-center {
    display: flex;
    justify-content: center;
  }
  .footer-col.tt-align-top-right {
    display: flex;
    justify-content: flex-end;
  }

  .footer-col.tt-align-center-left {
    display: flex;
    align-items: center;
  }
  .footer-col.tt-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-col.tt-align-center-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .footer-col.tt-align-bottom {
    display: flex;
    align-items: flex-end;
  }
  .footer-col.tt-align-bottom-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .footer-col.tt-align-bottom-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  /* Footer social */
  #tt-footer .footer-social {
    position: relative;
    line-height: 50px;
    height: 50px;
    pointer-events: auto;
  }
  #tt-footer .footer-social:before {
    /* expands the hover area */
    position: absolute;
    content: "";
    top: 0;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
  }
  #tt-footer .footer-social .footer-social-text {
    display: block;
    text-align: right;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
  }
  #tt-footer .footer-social .footer-social-text > span {
    margin-right: 13px;
  }
  #tt-footer .footer-social .social-buttons {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -50%, 0);
  }
  #tt-footer .footer-social .footer-social-text,
  #tt-footer .footer-social .social-buttons {
    transition: all 0.3s ease;
  }

  #tt-footer .footer-social:hover .footer-social-text {
    transform: translate3d(0, -50%, 0);
    opacity: 0;
    visibility: hidden;
  }
  #tt-footer .footer-social:hover .social-buttons {
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible;
  }
}

/* For smaller screens only 
  ============================ */
@media (max-width: 1024px) {
  #tt-footer {
    padding: 60px 0;
  }
  .tt-footer-inner {
    display: flex;
    flex-direction: column;
  }

  /* Footer columns */
  .footer-col {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
  .footer-col:first-child {
  }
  .footer-col:last-child {
  }
  .footer-col-inner {
  }

  /* Order footer columns */
  .footer-col.order-m-first {
    order: -1;
  }
  .footer-col.order-m-last {
    order: 10;
  }
  .footer-col.order-m-1 {
    order: 1;
  }
  .footer-col.order-m-2 {
    order: 2;
  }
  .footer-col.order-m-3 {
    order: 3;
  }
  .footer-col.order-m-4 {
    order: 4;
  }
  .footer-col.order-m-5 {
    order: 5;
  }
  .footer-col.order-m-6 {
    order: 6;
  }
  .footer-col.order-m-7 {
    order: 7;
  }
  .footer-col.order-m-8 {
    order: 8;
  }
  .footer-col.order-m-9 {
    order: 9;
  }
  .footer-col.order-m-10 {
    order: 10;
  }

  /* Footer social */
  #tt-footer .footer-social {
    margin-bottom: 10px;
  }
  #tt-footer .footer-social .footer-social-text {
    display: none;
  }
}
