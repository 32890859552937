.WalletInfoHeader {
  height: 25px;
  width: 100%;
  background: linear-gradient(to right, #eace00, #8bd4ef);
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.WalletInfoHeader p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.WalletInfoHeader_Connection:hover {
  cursor: pointer;
  opacity: 0.85;
}

.WalletInfoHeader_Connection {
  margin: 0;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 1.4rem;
  font-family: "SWEETREVENGE", sans-serif;
  font-weight: bold;
}
